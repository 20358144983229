import React, { useState } from 'react';
import { Container, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap';
import { isFunction, isArray } from 'lodash-es';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { CalendarRange } from 'react-bootstrap-icons';
import options from '@/constants/options';

/**
 * input
 */
interface IInputProps {
  keyId: string;
  value: any;
  onChange: any;
  onSearch: (_: void) => void;
}
const Input = ({ placeholder }: any) => {
  return function In(props: IInputProps) {
    return (
      <Form.Control
        size="sm"
        type="text"
        name={props.keyId}
        placeholder={placeholder}
        value={props.value || ''}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onKeyUp={(e: any) => {
          if (e.key && e.key === 'Enter') {
            props.onSearch();
          }
        }}
      />
    );
  };
};

/**
 * selectbox
 */
interface ISelectBoxComponent {
  keyId: string;
  value: any;
  onChange: any;
}
interface ISelectBoxOption {
  label: string;
  value: string;
}
interface ISelectBox {
  // 1개만 필요
  list?: ISelectBoxOption[];
  api?: any; // function
}
const SelectBox = ({ list, api }: ISelectBox) => {
  return function SelectBoxComponent(props: ISelectBoxComponent) {
    const [listValue, setListValue] = useState<any[] | null>(null);
    const [error, setError] = useState<boolean>(false);
    React.useEffect(() => {
      if (list) {
        setListValue(list);
      } else if (api) {
        api()
          .then((res: any) => {
            if (res && res.length > 0) {
              setListValue([
                {
                  label: '전체',
                  value: '',
                },
                ...res,
              ]);
            } else {
              setError(true);
            }
          })
          .catch((e: any) => {
            setError(true);
          });
      } else {
        console.error('list or api should exist.');
      }
    }, []);
    return (
      <>
        {error && <div>loading error...</div>}
        {!error && !listValue && (
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        {listValue && (
          <Form.Control as="select" size="sm" name={props.keyId} value={props.value} onChange={(e) => props.onChange(e.target.value)}>
            {listValue.map((obj: ISelectBoxOption, index: number) => {
              return (
                <option key={index} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
          </Form.Control>
        )}
      </>
    );
  };
};

/**
 * radio
 */
interface IRadioProps {
  keyId: string;
  value: any;
  onChange: any;
}
interface IRadio {
  label: string;
  value: string;
}
const Radio = ({ list }: any) => {
  return function Ra(props: IRadioProps) {
    return (
      <div>
        {list &&
          list.map((obj: IRadio, index: number) => {
            return (
              <Form.Check
                key={index}
                inline
                label={obj.label}
                type="radio"
                name={props.keyId}
                id={props.keyId + '-' + index}
                value={obj.value}
                checked={obj.value === props.value}
                onChange={(e) => props.onChange(e.target.value)}
              />
            );
          })}
      </div>
    );
  };
};

/**
 * 브라우저 주소 및 개발 코드에서는 2020-01-02 string 포맷으로 사용하고,
 * 실제 DatePicker 내부에 전달할때는 new Date(..)로 변환해서 사용.
 */
interface IDateRangeProps {
  keyId: string;
  value: {
    dateType: string;
    startDate: string;
    endDate: string;
  };
  values: any;
  onChange: any;
}
const CustomDateRangeWithType = (hideType?: boolean, dateTypeList?: { label: string; value: string }[]) => {
  return function Cu(props: IDateRangeProps) {
    const ExampleCustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
      <div style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
        <Form.Control size="sm" type="text" ref={ref} onClick={onClick} defaultValue={value} readOnly />
        <div style={{ padding: '0 6px', cursor: 'pointer' }}>
          <CalendarRange size="18" color="purple" onClick={onClick} />
        </div>
      </div>
    ));
    const getStartDate = () => {
      if (props.value.startDate) {
        const d: Date = new Date(props.value.startDate);
        if (!isNaN(d.getTime())) {
          return d;
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
    const getEndDate = () => {
      if (props.value.endDate) {
        const d: Date = new Date(props.value.endDate);
        if (!isNaN(d.getTime())) {
          return d;
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
    const getFormat = (date: Date) => {
      return format(date, 'yyyy-MM-dd');
    };
    return (
      <>
        <Form.Control
          as="select"
          size="sm"
          style={{
            width: '200px',
            display: hideType === true ? 'none' : 'flex',
          }}
          value={props.value.dateType}
          onChange={(e) => {
            props.onChange({
              ...props.value,
              dateType: e.target.value,
            });
          }}
        >
          {dateTypeList?.map((obj: ISelectBoxOption, index: number) => {
            return (
              <option key={index} value={obj.value}>
                {obj.label}
              </option>
            );
          }) ??
            options.dateTypeList.map((obj: ISelectBoxOption, index: number) => {
              return (
                <option key={index} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
        </Form.Control>
        <div
          style={{
            marginLeft: hideType ? '0px' : '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DatePicker
            locale="ko"
            selected={getStartDate()}
            dateFormat="yyyy-MM-dd"
            customInput={<ExampleCustomInput />}
            filterDate={(date) => {
              const endDate = getEndDate();
              if (endDate) {
                // ie11에서는 new Date() 생성시, 시간을 string으로 받을 수 없다.
                // WHY: 일단 날짜는 선택할 수 있어야 하므로.
                // return new Date(getFormat(date)) <= new Date(format(endDate, 'yyyy-MM-dd 23:59:59'));
                return new Date(getFormat(date)) <= new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
              }
              return true;
            }}
            onChange={(date: any) => {
              props.onChange({
                ...props.value,
                startDate: getFormat(date),
              });
            }}
          />
          <div style={{ width: '20px' }}> ~ </div>
          <DatePicker
            locale="ko"
            selected={getEndDate()}
            dateFormat="yyyy-MM-dd"
            customInput={<ExampleCustomInput />}
            filterDate={(date) => {
              const startDate = getStartDate();
              if (startDate) {
                // ie11에서는 new Date() 생성시, 시간을 string으로 받을 수 없다.
                // return new Date(format(startDate, 'yyyy-MM-dd 00:00:00')) <= new Date(getFormat(date));
                return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0) <= new Date(getFormat(date));
              }
              return true;
            }}
            onChange={(date: any) => {
              props.onChange({
                ...props.value,
                endDate: getFormat(date),
              });
            }}
          />
        </div>
      </>
    );
  };
};

interface IDateProps {
  keyId: string;
  value: string;
  onChange: any;
}
const DateSingle = () => {
  return function Da(props: IDateProps) {
    const getFormat = (date: Date) => {
      return format(date, 'yyyy-MM-dd');
    };
    return (
      <div style={{ width: '200px', display: 'flex' }}>
        <DatePicker
          locale="ko"
          selected={new Date(props.value)}
          dateFormat="yyyy-MM-dd"
          onChange={(date: any) => {
            props.onChange(getFormat(date));
          }}
        />
        <div style={{ padding: '0 10px' }}>
          <CalendarRange size="20" color="purple" />
        </div>
      </div>
    );
  };
};

export default {
  Input,
  SelectBox,
  Radio,
  DateSingle,
  CustomDateRangeWithType,
};
