import React from 'react';
import classNames from 'classnames';

interface IInputFormat {
  title: string;
  keyId: string;
  field: React.ReactNode;
  defaultValue?: any;
}
interface IProps {
  styleTemplate: any;
  inputFormat: any;
  values: any;
  setValues: any;
  onSearch: (_: void) => void;
}
export default function SimpleForm(props: IProps) {
  return (
    <div className={`search-form ${props.styleTemplate}`}>
      {props.inputFormat.map((obj: any, index: number) => {
        return (
          <div key={index} className={classNames('item')}>
            <div className="item-title">{obj.title}</div>
            <div className={`item-field keyId-${obj.keyId}`}>
              <obj.field
                keyId={obj.keyId}
                value={props.values[obj.keyId] || ''}
                values={props.values}
                onChange={(v: string) => {
                  console.log(obj.keyId, 'v', v, props.values);
                  props.setValues({
                    ...props.values,
                    [obj.keyId]: v,
                  });
                }}
                onSearch={props.onSearch}
              />
            </div>
          </div>
        );
      })}
      {/* <div>참고: {JSON.stringify(props.values)}</div> */}
    </div>
  );
}
