import api from '@/api';
import React, { useState } from 'react';
import { Form, InputGroup, Button, Modal, FormControl } from 'react-bootstrap';
import { Trash, Search } from 'react-bootstrap-icons';
import Pagination from '@/components/Pagination';
import { LoadingTable } from '@/components/TableHelper';
import { alertMessage } from '@/utils/apiUtil';

const countPerPage = 10;

interface IProps {
  data: any;
  setParentData: any;
}
export default function SongModal(props: IProps) {
  const [show, setShow] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [usedSearchText, setUsedSearchText] = useState<string>('');

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [songList, setSongList] = useState<any[]>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [chkAll, setChkAll] = useState<boolean>(false);

  /**
   * modal
   */
  const handleClose = () => {
    setShow(false);
    setSearchText('');
    setUsedSearchText('');
    setActivePage(1);
  };
  const handleShow = () => {
    setActivePage(1);
    setSearchText('');
    setUsedSearchText('');
    setShow(true);
    onSearch(1, '');
  };
  // 모달: 검색
  const onSearch = async (page: number, text: string) => {
    setActivePage(page);
    if (text !== '') {
      setUsedSearchText(text);
    }

    try {
      const res: any = await api.songSearch({
        skip: (page - 1) * countPerPage,
        keyword: text,
      });
      console.log('res', res);
      if (!res.list) {
        throw new Error('res.list is null');
      }
      res.list.forEach((obj: any) => {
        obj.checked = false;
      });
      setSongList(res.list || []);
      setTotalDataCount(res.total || 0);
    } catch (e) {
      alertMessage(e);
      setSongList([]);
      setTotalDataCount(0);
    } finally {
      setIsLoading(false);
    }
  };
  // 모달: 엔터처리
  const onKeyUp = (e: any) => {
    if (e.key && e.key === 'Enter') {
      onSearch(1, searchText);
    }
  };
  // 모달: 추가/삭제 버튼 유무
  const isExist = (id: number) => {
    const result = props.data.filter((obj: any) => obj.id === id);
    return result.length > 0;
  };
  // 모달: 추가
  const onAdd = (obj: any) => {
    props.setParentData([...props.data, obj]);

    setChkAll(false);
  };
  // 모달: 삭제
  const onRemove = (id: number) => {
    const temp = props.data.filter((obj: any) => obj.id !== id);
    props.setParentData(temp);

    decideChkAll(temp);
  };
  // 모달: 페이지네이션 버튼
  const onChangePage = (num: number) => {
    onSearch(num, usedSearchText);
  };
  // 체크박스:전체
  const decideChkAll = (list: any) => {
    if (list.length > 0 && list.filter((obj: any) => obj.checked).length === list.length) {
      setChkAll(true);
    } else {
      setChkAll(false);
    }
  };
  // 체크된 것 삭제
  const deleteChecked = () => {
    const temp = props.data.filter((obj: any) => !obj.checked);
    props.setParentData(temp);

    decideChkAll(temp);
  };
  return (
    <>
      <div className="h2-title" style={{ margin: '10px 0' }}>
        하위 수록곡 <span className="text-required">*</span>
      </div>
      <div className="detail-section">
        <table className="table song-modal">
          <thead>
            <tr>
              <th className="checkbox-all" style={{ width: '100px' }}>
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox-all`}
                  checked={chkAll}
                  onChange={(e) => {
                    const temp = JSON.parse(JSON.stringify(props.data));
                    if (e.target.checked) {
                      temp.forEach((obj: any) => {
                        obj.checked = true;
                      });
                      props.setParentData(temp);
                    } else {
                      temp.forEach((obj: any) => {
                        obj.checked = false;
                      });
                      props.setParentData(temp);
                    }
                    setChkAll(e.target.checked);
                  }}
                />
              </th>

              <th>번호</th>
              <th>제목</th>
              <th>가수명</th>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.map((obj: any, index: number) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      obj.checked = !obj.checked;
                      const temp = JSON.parse(JSON.stringify(props.data));
                      props.setParentData(temp);

                      decideChkAll(temp);
                    }}
                  >
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`default-checkbox-${index}`}
                        checked={obj.checked}
                        onChange={(e) => {
                          // obj.checked = !obj.checked;
                          // const temp = JSON.parse(JSON.stringify(props.data));
                          // props.setParentData(temp);
                          // decideChkAll(temp);
                        }}
                      />
                    </td>
                    <td>{obj.id}</td>
                    <td>{obj.title}</td>
                    <td>{obj.singer}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={deleteChecked}
              disabled={props.data.filter((obj: any) => obj.checked).length === 0}
            >
              <Trash size="20" color="black" /> 선택 수록곡 삭제
            </Button>
            <Button size="sm" variant="outline-primary" onClick={handleShow} focus-name="button-add-song">
              <Search size="16" color="blue" /> 수록곡 추가
            </Button>
          </>
          <Modal
            animation={false} // Warning: findDOMNode is deprecated in StrictMode.
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            dialogClassName="modal-60w"
          >
            <Modal.Header closeButton>
              <Modal.Title>수록곡 검색</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ padding: '20px' }}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="곡번호, 제목, 가수명으로 검색하실 수 있습니다."
                    aria-label="곡번호, 제목, 가수명으로 검색하실 수 있습니다."
                    aria-describedby=""
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyUp={onKeyUp}
                  />
                  <InputGroup.Append>
                    <Button variant="outline-secondary" size="sm" onClick={(e: any) => onSearch(1, searchText)}>
                      <Search size="20" color="black" />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <table className="table song-modal">
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>제목</th>
                      <th>가수명</th>
                      <th style={{ width: '100px' }}>추가</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan={4} style={{ width: '100px' }}>
                          <LoadingTable />
                        </td>
                      </tr>
                    )}
                    {!isLoading && (!songList || songList.length === 0) && (
                      <tr style={{ width: '100px' }}>
                        <td colSpan={4}>검색 결과가 없습니다.</td>
                      </tr>
                    )}
                    {!isLoading &&
                      songList &&
                      songList.length > 0 &&
                      songList.map((obj: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              if (isExist(obj.id)) {
                                onRemove(obj.id);
                              } else {
                                onAdd(obj);
                              }
                            }}
                          >
                            <td>{obj.id}</td>
                            <td>{obj.title}</td>
                            <td>{obj.singer}</td>
                            <td>
                              {isExist(obj.id) ? (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={(e) => {
                                    // onRemove(obj.id);
                                  }}
                                  block
                                >
                                  삭제
                                </Button>
                              ) : (
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={(e) => {
                                    // onAdd(obj);
                                  }}
                                  block
                                >
                                  추가
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="pagination-box">
                  <Pagination
                    totalPages={Math.ceil(totalDataCount / countPerPage)}
                    onChangePage={onChangePage}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    countPerPage={countPerPage}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
