import Login from '@/pages/Login';
import Hot from '@/pages/Hot';
import HotDetail from '@/pages/HotDetail';
import Genre from '@/pages/Genre';
import GenreDetail from '@/pages/GenreDetail';
import Artist from '@/pages/Artist';
import ArtistDetail from '@/pages/ArtistDetail';
import Mood from '@/pages/Mood';
import MoodDetail from '@/pages/MoodDetail';
import Curation from '@/pages/Curation';
import CurationDetail from '@/pages/CurationDetail';
import Times from '@/pages/Times';
import TimesDetail from '@/pages/TimesDetail';
import MainBanner from '@/pages/MainBanner';
import MainBannerDetail from '@/pages/MainBannerDetail';
import Member from '@/pages/Member';
import MemberDetail from '@/pages/MemberDetail';
import Notice from '@/pages/Notice';
import NoticeDetail from '@/pages/NoticeDetail';
import Promotion from '@/pages/Promotion';
import PromotionDetail from '@/pages/PromotionDetail';
import Terms from '@/pages/Terms';
import TermsDetail from '@/pages/TermsDetail';
import PrivacyCollection from '@/pages/PrivacyCollection';
import PrivacyCollectionDetail from '@/pages/PrivacyCollectionDetail';
import PrivacyProcess from '@/pages/PrivacyProcess';
import PrivacyProcessDetail from '@/pages/PrivacyProcessDetail';
import MusicRecommendUpload from '@/pages/MusicRecommendUpload';
import BixbyUpload from '@/pages/BixbyUpload';
import SingerNumber from '@/pages/SingerNumber';
import SingerNumberDetail from '@/pages/SingerNumberDetail';
import SingerSimilar from '@/pages/SingerSimilar';
import SingerSimilarDetail from '@/pages/SingerSimilarDetail';
import SongSimilar from '@/pages/SongSimilar';
import SongSimilarDetail from '@/pages/SongSimilarDetail';
import SongInfoDetail from '@/pages/SongInfoDetail';
import SongInfo from '@/pages/SongInfo';
import SongLog from '@/pages/SongLog';
import Vocabulary from '@/pages/Vocabulary';
import VocabularyDetail from '@/pages/VocabularyDetail';
import VideoSwitch from '@/pages/VideoSwitch';
import React from 'react';
import Payment from './pages/Payment';

/**
 * 주의
 * 0.
 * routes.ts 구성은 화면 좌측 side menu 에 영향을 준다.
 * routes.ts 구성은 화면 상단 title명에 영향을 준다.
 *
 * 1. 주로 메뉴는 [조회] / [상세]의 짝으로 이루어진다. (로그인, 404, 기타 페이지 제외)
 * 2. [상세] 메뉴는 isHideMenu:true 로 설정해야 좌측 side menu에 나타나지 않는다.
 * 3. 배열 내에서 [상세] 메뉴는 [조회] 메뉴보다 먼저 정의해야 한다. router search에서 먼저 검색되도록!
 */
interface IChild {
  title: string;
  path: string;
  component: React.ReactNode;
  isHideMenu?: boolean;
}
interface IProps {
  title: string;
  path?: '/';
  component?: React.ReactNode;
  children?: IChild[];
  noLayout?: boolean;
}
const routes: IProps[] = [
  {
    title: '컨텐츠 관리',
    children: [
      {
        title: 'Hot & New 관리',
        path: '/hot/:mode',
        component: HotDetail,
        isHideMenu: true,
      },
      {
        title: 'Hot & New 관리',
        path: '/hot',
        component: Hot,
      },
      {
        title: '장르별 인기곡 관리',
        path: '/genre/:mode',
        component: GenreDetail,
        isHideMenu: true,
      },
      {
        title: '장르별 인기곡 관리',
        path: '/genre',
        component: Genre,
      },
      {
        title: '주목받는 아티스트 관리',
        path: '/artist/:mode',
        component: ArtistDetail,
        isHideMenu: true,
      },
      {
        title: '주목받는 아티스트 관리',
        path: '/artist',
        component: Artist,
      },
      {
        title: '무드별 관리',
        path: '/mood/:mode',
        component: MoodDetail,
        isHideMenu: true,
      },
      {
        title: '무드별 관리',
        path: '/mood',
        component: Mood,
      },
      {
        title: '시대별 관리',
        path: '/times/:mode',
        component: TimesDetail,
        isHideMenu: true,
      },
      {
        title: '시대별 관리',
        path: '/times',
        component: Times,
      },
      {
        title: '금영 큐레이션',
        path: '/curation/:mode',
        component: CurationDetail,
        isHideMenu: true,
      },
      {
        title: '금영 큐레이션',
        path: '/curation',
        component: Curation,
      },
      {
        title: '메인배너 관리',
        path: '/main-banner/:mode',
        component: MainBannerDetail,
        isHideMenu: true,
      },
      {
        title: '메인배너 관리',
        path: '/main-banner',
        component: MainBanner,
      },
      {
        title: '공지사항 관리',
        path: '/notice/:mode',
        component: NoticeDetail,
        isHideMenu: true,
      },
      {
        title: '공지사항 관리',
        path: '/notice',
        component: Notice,
      },
      {
        title: '뮤직 비디오 재생',
        path: '/video',
        component: VideoSwitch,
      },
    ],
  },
  {
    title: '회원 관리',
    children: [
      {
        title: '회원관리',
        path: '/member/:mode',
        component: MemberDetail,
        isHideMenu: true,
      },
      {
        title: '회원관리',
        path: '/member',
        component: Member,
      },
      {
        title: '구매정보',
        path: '/payment',
        component: Payment,
      },
      {
        title: '프로모코드 관리',
        path: '/promotion/:mode',
        component: PromotionDetail,
        isHideMenu: true,
      },
      {
        title: '프로모코드 관리',
        path: '/promotion',
        component: Promotion,
      },
      {
        title: '회원 곡 히스토리',
        path: '/song-log',
        component: SongLog,
      },
    ],
  },
  {
    title: '약관 관리',
    children: [
      {
        title: '이용약관',
        path: '/terms/:mode',
        component: TermsDetail,
        isHideMenu: true,
      },
      {
        title: '이용약관',
        path: '/terms',
        component: Terms,
      },
      {
        title: '개인정보 수집이용 동의',
        path: '/privacy-collection/:mode',
        component: PrivacyCollectionDetail,
        isHideMenu: true,
      },
      {
        title: '개인정보 수집이용 동의',
        path: '/privacy-collection',
        component: PrivacyCollection,
      },
      {
        title: '개인정보처리방침',
        path: '/privacy-process/:mode',
        component: PrivacyProcessDetail,
        isHideMenu: true,
      },
      {
        title: '개인정보처리방침',
        path: '/privacy-process',
        component: PrivacyProcess,
      },
    ],
  },
  {
    title: '데이터 관리',
    children: [
      {
        title: '가수번호 관리',
        path: '/singer-number/:mode',
        component: SingerNumberDetail,
        isHideMenu: true,
      },
      {
        title: '가수번호 관리',
        path: '/singer-number',
        component: SingerNumber,
      },
      {
        title: '곡정보 관리',
        path: '/song-info/:mode',
        component: SongInfoDetail,
        isHideMenu: true,
      },
      {
        title: '곡정보 관리',
        path: '/song-info',
        component: SongInfo,
      },
      {
        title: '음악 추천 DB 업로드',
        path: '/music-recommend-upload',
        component: MusicRecommendUpload,
      },
      {
        title: '빅스비 DB 업로드',
        path: '/bixby-upload',
        component: BixbyUpload,
      },
      {
        title: '가수명 유사검색어 관리',
        path: '/singer-similar/:mode',
        component: SingerSimilarDetail,
        isHideMenu: true,
      },
      {
        title: '가수명 유사검색어 관리',
        path: '/singer-similar',
        component: SingerSimilar,
      },
      {
        title: '곡제목 유사검색어 관리',
        path: '/song-similar/:mode',
        component: SongSimilarDetail,
        isHideMenu: true,
      },
      {
        title: '곡제목 유사검색어 관리',
        path: '/song-similar',
        component: SongSimilar,
      },
      {
        title: '어휘 사전',
        path: '/vocabulary/:mode',
        component: VocabularyDetail,
        isHideMenu: true,
      },
      {
        title: '어휘사전',
        path: '/vocabulary',
        component: Vocabulary,
      },
    ],
  },
  {
    // 1depth path는 left side 메뉴에 노출하지 않음.
    title: '로그인',
    path: '/',
    component: Login,
    noLayout: true,
  },
];

export default routes;
