import React from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingTable = () => {
  return (
    <div className="table-loading">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};
export const ErrorTable = () => {
  return <div className="table-error-data">처리중 오류가 발생하였습니다.</div>;
};

export const EmptyDataTable = () => {
  return <div className="table-empty-data">데이터가 없습니다.</div>;
};
