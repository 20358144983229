import TvBrandContext from '@/TvBrandContext';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';

interface IProps {
  data?: any;
  pageType?: {
    mode: string;
    subTitle?: string;
    id: string;
  };
  setParentData?: any;
}

export default function Component(props: IProps) {
  const { brand, setBrand } = useContext(TvBrandContext);

  const toggleTVBrand = async (keyword: string) => {
    setBrand(keyword);
  };

  const showButtons = () => {
    if (!props.pageType) {
      return true;
    }

    if (props.pageType && props.pageType.mode !== 'detail') {
      return true;
    }

    return false;
  };

  return (
    <div>
      {showButtons() && (
        <div>
          <Button
            size="sm"
            variant={brand === 'samsung' ? 'primary' : 'ontline-secondary'}
            color="black"
            onClick={() => toggleTVBrand('samsung')}
          >
            SAMSUNG
          </Button>
          <Button
            size="sm"
            variant={brand === 'lg' ? 'primary' : 'ontline-secondary'}
            color="black"
            style={{ marginLeft: '10px' }}
            onClick={() => toggleTVBrand('lg')}
          >
            LG
          </Button>
        </div>
      )}
    </div>
  );
}
