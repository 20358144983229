import React, { useContext, useState } from 'react';
import * as yup from 'yup';

import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import TVBrandButtons from '@/components/TVBrandButtons';
import TvBrandContext from '@/TvBrandContext';
import ExcelUploadModal from '@/components/ExcelUploadModal';

export default function Component(props: any) {
  const { brand, setBrand } = useContext(TvBrandContext);

  return (
    <div>
      <TVBrandButtons data={brand} pageType={props.pageType} setParentData={setBrand}></TVBrandButtons>
      <SimpleDetailModule
        isSongModal={true}
        pageName="ARTIST"
        pageType={props.pageType}
        api={api.contents}
        tvBrand={brand}
        fieldFormat={[
          {
            keyId: 'title',
            title: '카테고리명',
            field: DetailField.InputCategory,
            schema: yup.string().required('카테고리명을 입력해주세요.'),
          },
          {
            keyId: 'color',
            title: '배너 배경 컬러',
            field: DetailField.ColorPicker,
            schema: yup.string().nullable().required('컬러를 선택해주세요.'), // DB 상세데이터에 null로 들어오는 경우가 있음.
          },
          {
            keyId: 'regDate',
            title: '등록일시',
            field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
          },
        ]}
      />

      {props.pageType.mode === 'detail' && (
        <div style={{ paddingTop: 10 }}>
          <ExcelUploadModal api={api.contents} title="엑셀 업로드" pageName="CATEGORY_ARTIST" pageType={props.pageType} />
        </div>
      )}
    </div>
  );
}
