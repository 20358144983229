import React, { useContext, useState } from 'react';

import DraggableModule from '@/modules/DraggableModule';
import api from '@/api';
import TVBrandButtons from '@/components/TVBrandButtons';

import { Button } from 'react-bootstrap';
import TvBrandContext from '@/TvBrandContext';

export default function Component(props: any) {
  const { brand } = useContext(TvBrandContext);

  return (
    <div>
      <TVBrandButtons></TVBrandButtons>

      <DraggableModule
        pageName={brand === 'samsung' ? 'HOTANDNEW' : 'CHART'}
        api={api.contents}
        theads={['DRAGGABLE', '카테고리명', '하위 수록곡', '등록일']}
        columns={['DRAGGABLE', 'title', 'songs', 'regDate']}
        link={{ column: 'title', key: 'id' }}
      />
    </div>
  );
}
