import React from 'react';
import classNames from 'classnames';
import { isArray, isFunction, isString } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { Justify } from 'react-bootstrap-icons';
import { detect } from 'detect-browser';
import { Container, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap';

const browser = detect();
if (browser) {
  // console.log('---브라우저 이름', browser.name);
  // console.log('---브라우저 버전',browser.version);
  // console.log('---기기 운영체제',browser.os);
}

enum EFFECT {
  GRABBING = 'effect-grabbing',
  TOP = 'effect-top',
  BOTTOM = 'effect-bottom',
}
// const data = [
//   { title: '0. New', count: '99곡', 'regDate': '2020-01-11 11:31:11' },
//   { title: '1. Weekly Hot', count: '91곡', 'regDate': '2020-02-11 11:31:11' },
//   { title: '2. Steady', count: '91곡', 'regDate': '2020-03-11 11:31:11' },
//   { title: '3. Now On Tv', count: '191곡', 'regDate': '2020-04-11 11:31:11' },
// ];
interface IProps {
  isLoading: boolean;
  data: any[];
  theads: string[];
  columns: string[];
  link: {
    column: string;
    key: string;
  };
  setData: any;
}
export default function Component(props: IProps) {
  const [startPos, setStartPos] = React.useState('-1');
  const [direction, setDirection] = React.useState('');
  const [endPos, setEndPos] = React.useState('-1');
  const location = useLocation();

  const _onDragOver = (e: any) => {
    e.preventDefault();
  };
  const _onDragStart = (e: any, position: string) => {
    // console.log('_onDragStart');
    setStartPos(e.target.dataset.position);
    e.target.classList.add(EFFECT.GRABBING);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text', position); // ie11에서 string으로 해야함.
  };
  const _onDragEnter = (e: any, effectClass: string, position: string) => {
    setEndPos(position);
    // console.log('onDragEnter', position, 'effectClass:', effectClass);
    if (startPos === position) return;
    if (parseInt(startPos) + 1 === parseInt(position) && effectClass === EFFECT.TOP) return; // 바로 아래는 무시
    if (parseInt(startPos) - 1 === parseInt(position) && effectClass === EFFECT.BOTTOM) return; // 바로 위는 무시
    // e.target.parentElement.classList.add(effectClass);
    setDirection(effectClass);
  };
  const _onDragEnd = (e: any) => {
    console.log('_onDragEnd');
    // reset style
    e.target.classList.remove(EFFECT.GRABBING);
    if (startPos === endPos) {
      console.log('startPos === endPos: nothing happen.');
      resetStyle();
      return;
    }

    if (browser && browser.name !== 'ie') {
      e.dataTransfer.dropEffect = 'move'; // ie11에서 사용권한 없음.
    }
    if (direction === EFFECT.TOP && parseInt(startPos) === parseInt(endPos) - 1) {
      resetStyle();
      return;
    }
    if (direction === EFFECT.BOTTOM && parseInt(startPos) === parseInt(endPos) + 1) {
      resetStyle();
      return;
    }

    const lists2 = JSON.parse(JSON.stringify(props.data));
    const remain = lists2.splice(startPos, 1);
    // console.log('remain', JSON.stringify(remain[0]));
    // console.log('lists2', endPos, JSON.stringify(lists2, null, 2));
    if (startPos < endPos && direction === EFFECT.TOP) {
      lists2.splice(parseInt(endPos) - 1, 0, remain[0]);
    } else if (endPos < startPos && direction === EFFECT.BOTTOM) {
      lists2.splice(parseInt(endPos) + 1, 0, remain[0]);
    } else {
      lists2.splice(endPos, 0, remain[0]);
    }
    props.setData(lists2);

    resetStyle();
  };
  const resetStyle = () => {
    setDirection('');
    setStartPos('-1');
    setEndPos('-1');
  };
  const isTop = (index: string) => {
    if (startPos === index) return false;
    return endPos === index && direction === EFFECT.TOP;
  };
  const isBottom = (index: string) => {
    if (startPos === index) return false;
    return endPos === index && direction === EFFECT.BOTTOM;
  };
  React.useEffect(() => {}, []);
  return (
    <>
      <div className="draggable-box">
        <div className="thead-box">
          {props.theads.map((col: string, index: number) => {
            if (col === 'DRAGGABLE') {
              return (
                <div key={index} className="col-item col-dropzon">
                  <Justify size="20" color="gray" />
                </div>
              );
            } else {
              return (
                <div key={index} className="col-item col-field">
                  {col}
                </div>
              );
            }
          })}
        </div>
        <div className="tbody-box">
          {props.isLoading && (
            <div className="table-loading">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {!props.isLoading && (!props.data || props.data.length === 0) && <div className="table-empty-data">데이터가 없습니다.</div>}
          {!props.isLoading &&
            props.data &&
            isArray(props.data) &&
            props.data.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={classNames('draggable-row', { [EFFECT.TOP]: isTop(index + ''), [EFFECT.BOTTOM]: isBottom(index + '') })}
                  data-position={index + ''}
                  onDragStart={(e) => _onDragStart(e, index + '')}
                  onDragOver={_onDragOver}
                  onDragEnd={_onDragEnd}
                  // onDrop={_onDrop} // ie11에서 작동안함
                  draggable
                >
                  {props.columns.map((col: any, index2: number) => {
                    if (col === 'DRAGGABLE') {
                      return (
                        <div key={index2} className="col-item col-dropzon">
                          <div className="dropzon-top" onDragEnter={(e) => _onDragEnter(e, EFFECT.TOP, index + '')} />
                          <div className="dropzon-icon">
                            <Justify size="20" color="purple" />
                          </div>
                          <div className="dropzon-bottom" onDragEnter={(e) => _onDragEnter(e, EFFECT.BOTTOM, index + '')} />
                        </div>
                      );
                    } else if (col === props.link.column) {
                      return (
                        <div key={index2} className="col-item col-field">
                          <a href={`${location.pathname}/detail/${item[props.link.key]}`}>{item[col]}</a>
                        </div>
                      );
                    } else {
                      return (
                        <div key={index2} className="col-item col-field">
                          {item[col]}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

// onDrop: https://www.w3schools.com/html/tryit.asp?filename=tryhtml5_draganddrop
