import React, { useContext } from 'react';
import classNames from 'classnames';
import { getUsername } from '@/token';
import { PersonFill } from 'react-bootstrap-icons';
import api from '@/api';
import TvBrandContext from '@/TvBrandContext';
import { Button } from 'react-bootstrap';

export default function Component({ routes }: { routes: any }) {
  const logoutFn = () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    api.auth.logout();
  };

  const customSync = async () => {
    if (
      window.confirm(
        '음원 서버에 저장된 음원을 분석하여 DB에 저장합니다.\n10분 가량 소요되며, 해당 작업을 실행하며 다른 작업을 하시면 작업에 영향을 줄 수 있습니다.',
      )
    ) {
      await api.syncData();
      alert('요청 완료');
    } else {
      return;
    }
  };

  const cacheRefresh = async () => {
    if (
      window.confirm(
        '현재까지 admin에 업데이트 된 내용을 TV앱에 적용합니다.\n반영이 필요한 데이터 양에 따라 최대 2분이 소요될 수 있습니다.',
      )
    ) {
      console.log('ok');
      await api.cacheRefresh();
      alert('요청 완료');
    } else {
      return;
    }
  };

  const { brand } = useContext(TvBrandContext);

  return (
    <>
      <div className="layout-header">
        <div className="center-layout-container">{brand}</div>
        <div className="icon">
          <PersonFill size="18" color="black" />
        </div>
        <div className="name">{getUsername()}</div>
        <div className="logout" style={{ cursor: 'pointer' }} onClick={logoutFn}>
          로그아웃
        </div>
      </div>
      <div className="layout-sidebar">
        <div className="menus">
          <div className="logo">
            <img src="/assets/images/img_ky_logo_white.png" alt="" style={{ width: '160px' }} />
            <div>
              <div className="mb-2 text-center">
                <Button size="sm" variant="info" style={{ width: 'auto' }} onClick={cacheRefresh}>
                  TV앱에 적용
                </Button>
              </div>
              <div className="mb-2 text-center">
                <Button size="sm" variant="info" style={{ width: 'auto' }} onClick={customSync}>
                  음원 업데이트
                </Button>
              </div>
            </div>
          </div>
          {routes
            .filter((obj: any) => obj.children)
            .map((obj: any, index: number) => {
              return (
                <div key={index}>
                  <div className="title1">{obj.title}</div>
                  {obj.children
                    .filter((obj2: any) => !obj2.isHideMenu)
                    .map((obj2: any, index2: number) => {
                      return (
                        <a
                          href={obj2.path}
                          key={obj2.title}
                          className={classNames('title2', {
                            active: window.location.href.indexOf(obj2.path) > -1,
                          })}
                        >
                          {obj2.title}
                        </a>
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
