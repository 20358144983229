import IgnoreError from '@/errors/IgnoreError';
import { JOB_STATUS } from '@/constants/constants';

export const makeSimpleParams = (params: any, filterKeyList: string[]) => {
  const newParams: any = {};
  filterKeyList.forEach((key: string) => {
    if (params[key] !== '') {
      newParams[key] = params[key];
    }
  });
  // clone
  return JSON.parse(JSON.stringify(newParams));
};
export const makePageParams = (params: any, filterKeyList: string[]) => {
  const newParams: any = {
    skip: params.skip,
    take: params.take,
  };
  filterKeyList.forEach((key: string) => {
    if (params[key] !== '') {
      newParams[key] = params[key];
    }
  });
  return newParams;
};
export const alertMessage = (e: any, message?: string) => {
  if (!(e instanceof IgnoreError)) {
    console.error('e', e);
    if (!message) {
      message = '처리중 오류가 발생하였습니다.';
    }
    alert(`${message}\n[e.message]: \n${e?.message}\n[e.response.data]: \n${JSON.stringify(e?.response?.data)}`);
  }
};

export const wait = (ms: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
};

// 엑셀 업로드에 사용하는 폴링
export const pollingApi = async (apiFn: any, param?: any) => {
  let res: any = null;
  try {
    res = await apiFn(param);
    console.log('polling res', res);
    if (!res) return null;

    if (res.status === JOB_STATUS.PROGRESS) {
      await wait(10000); // 10초 후에 다시 요청
      const res2: any = await pollingApi(apiFn, param);
      return res2;
    } else {
      return res; // 성공이든, 실패든 일단 종료.
    }
  } catch (e) {
    // 요청 실패시 재요청
    console.error('api요청 실패. 10초후 재시도.');
    await wait(10000); // 10초 후에 다시 요청
    const res2: any = await pollingApi(apiFn, param);
    return res2;
  }
};
