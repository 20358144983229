import React from 'react';
import SearchModule from '@/modules/SearchModule';

import SearchField from '@/components/SearchField';
import options from '@/constants/options';
import api from '@/api';
import { find } from 'lodash-es';

export default function Component(props: any) {
  return (
    <>
      <SearchModule
        {...props}
        api={api.notice}
        inputFormat={[
          {
            title: '키워드',
            keyId: 'keyword',
            field: SearchField.Input({ placeholder: '국문제목, 영문제목으로 검색하실 수 있습니다.' }),
          },
        ]}
        tableFormat={{
          theads: ['게시구분', '국문제목', '영문제목', '등록일'],
          columns: [
            (item: any) => {
              const option = find(options.noticeRaio3List, { value: item.category });
              if (option) {
                return option.label;
              }
            },
            'koTitle',
            'enTitle',
            'regDate',
          ],
          link: { column: 'koTitle', key: 'id' },
        }}
      />
    </>
  );
}
