import api from '@/api';
import MusicVideoButtons from '@/components/MusicVideoButtons';

export default function Component(props: any) {
  return (
    <div>
      <MusicVideoButtons pageName="VIDEO" api={api.videoSwitch}></MusicVideoButtons>
    </div>
  );
}
