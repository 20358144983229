import React from 'react';
import { Container, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { alertMessage } from '@/utils/apiUtil';
import { initToken } from '@/token';
import api from '@/api';

import '@/styles/login.scss';

const schema = yup.object({
  loginId: yup.string().required('ID를 입력해주세요.'),
  password: yup.string().required('Password를 입력해주세요.'),
});

const itemsData = {
  loginId: '',
  password: '',
};
export default function Component() {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, validateForm } = useFormik({
    initialValues: itemsData,
    validationSchema: schema,
    onSubmit: (values) => {
      console.log('onSubmit', values);
    },
  });
  const loginFn = async () => {
    if (!(await validate())) {
      return;
    }
    try {
      const result: any = await api.auth.login(values);
      console.log('login result', result);
      if (result && result.success) {
        if (!result.accessToken || !result.refreshToken) {
          throw new Error('accessToken or refreshToken is null');
        }
        const parsingResult: boolean = initToken(result);
        if (!parsingResult) {
          alert('잘못된 accessToken입니다.');
          return;
        }
        window.location.href = '/hot'; // 기본 시작 위치
      } else {
        alert('ID를 찾을 수 없거나 Password가 일치하지 않습니다.');
      }
    } catch (e) {
      alertMessage(e);
    }
  };
  const validate = async () => {
    const errors: any = await validateForm();
    console.log('validateForm, errors', errors);
    console.log('validateForm, values', values);
    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      // alert(errors[key]);
      const elem: HTMLInputElement | null = document.querySelector(`[focus-name='${key}']`);
      if (elem) {
        elem.focus();
      } else {
        console.error('elem.focus();', 'focus-name=', key, 'elem', elem);
      }
      return false;
    } else {
      console.log('에러 없음.');
    }
    return true;
  };
  const onKeyUp = (e: any) => {
    if (e.key && e.key === 'Enter') {
      loginFn();
    }
  };
  return (
    <div className="login-container">
      <div style={{ width: '400px', height: '200px' }}>
        <img src="/assets/images/img_ky_logo_color.png" alt="" style={{ width: '100%' }} />
      </div>
      <Form style={{ width: '400px', marginTop: '20px' }} noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          {/* <Form.Label>ID</Form.Label> */}
          <Form.Control
            type="text"
            placeholder="ID를 입력해주세요."
            focus-name="loginId"
            name="loginId"
            value={values.loginId}
            onChange={handleChange}
            isInvalid={!!touched.loginId && !!errors.loginId}
          />
          {errors.loginId && <div className="text-error">{errors.loginId}</div>}
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          {/* <Form.Label>Password</Form.Label> */}
          <Form.Control
            type="password"
            placeholder="Password를 입력해주세요."
            focus-name="password"
            name="password"
            value={values.password}
            onChange={(e) => {
              setFieldValue('password', e.target.value);
            }}
            isInvalid={!!touched.password && !!errors.password}
            onKeyUp={onKeyUp}
          />
          {errors.password && <div className="text-error">{errors.password}</div>}
        </Form.Group>
        <Button variant="primary" block onClick={loginFn}>
          로그인
        </Button>
      </Form>
    </div>
  );
}
