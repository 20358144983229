import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash-es';
import qs from 'qs';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import options from '@/constants/options';
import { CATEGORY } from '@/constants/constants';
import api from '@/api';
import { Button } from 'react-bootstrap';

export default function Component(props: any) {
  const location = useLocation();

  const currentSearchTvBrand = useMemo(() => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    return queryString.tvBrand || '';
  }, [location.search]);

  return (
    <>
      <SearchModule
        {...props}
        api={api.member}
        onSearchValidation={(params: any) => {
          if (params.customDateRangeWithType.startDate || params.customDateRangeWithType.endDate) {
            if (!params.customDateRangeWithType.startDate || !params.customDateRangeWithType.endDate) {
              alert('시작날짜와 종료날짜 모두 선택해주세요.');
              return false;
            }
          }
          return true;
        }}
        styleTemplate="template-member"
        inputFormat={[
          {
            title: '이메일',
            keyId: 'email',
            field: SearchField.Input({
              placeholder: '사용자 이메일로 검색하실 수 있습니다.',
            }),
          },
          {
            title: '국적',
            keyId: 'country',
            field: SearchField.SelectBox({ api: api.member.getCountry }),
          },
          {
            title: '활성상품',
            keyId: 'activatedProductId',
            field: SearchField.SelectBox({
              api: () => api.getAllProductOptions(),
            }),
          },
          {
            title: 'TV 브랜드',
            keyId: 'tvBrand',
            field: SearchField.SelectBox({
              list: [
                { label: '전체', value: '' },
                { label: 'SAMSUNG', value: 'SAMSUNG' },
                { label: 'LG', value: 'LG' },
              ],
            }),
          },
          {
            title: '날짜',
            keyId: 'customDateRangeWithType',
            field: SearchField.CustomDateRangeWithType(),
            defaultValue: {
              dateType: options.dateTypeList[0].value,
              startDate: null,
              endDate: null,
            },
          },
        ]}
        tableFormat={{
          // theads: ['이메일', '국적', '현재활성상품', '자동결제', '현재활성상품', '자동결제', '하위프로필', '등록일', '최근접속일'],
          theads: () => (
            <>
              <tr>
                <th rowSpan={2}>이메일</th>
                <th rowSpan={2}>국적</th>
                <th colSpan={2}>K-araoke</th>
                <th colSpan={2}>금영노래방(빅스비)</th>
                <th rowSpan={2}>하위프로필</th>
                <th rowSpan={2}>등록일</th>
                <th rowSpan={2}>최근접속일</th>
              </tr>
              <tr>
                <th>현재활성상품</th>
                <th>자동결제</th>
                <th>현재활성상품</th>
                <th>자동결제</th>
              </tr>
            </>
          ),
          columns: [
            (item: any) => (
              <a href={`${location.pathname}/info/${item.id}${get(item, 'webosKaraokeProduct') ? '?tvBrand=LG' : ''}`}>{item.email}</a>
            ),
            'country',
            (item: any) => get(item, 'kAraokeProduct.productName') || get(item, 'webosKaraokeProduct.productName') || '-',
            (item: object) =>
              get(item, 'kAraokeProduct.subscription') || get(item, 'webosKaraokeProduct.subscription') === true ? 'Y' : '-',
            (item: any) => get(item, 'bixbyProduct.productName') || '-',
            (item: any) => (get(item, 'bixbyProduct.subscription') === true ? 'Y' : '-'),
            'profileCount',
            (item: any) => item.regDate,
            (item: any) => item.loginDate,
          ],
        }}
        customCreateButton={<></>}
        bottomLeftMenu={({ totalDataCount, params }: any) => {
          if (totalDataCount === 0) return <></>;
          return (
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                api.member.downloadListToExcel(params);
              }}
            >
              결과 다운로드
            </Button>
          );
        }}
      />
    </>
  );
}
