import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface IProps {
  label: string;
  onClick: any;
  isProcessing: boolean;
  variant?: string;
  type?: string;
  style?: any;
}

export default function Component({
  label = '',
  variant = 'primary',
  type = '', // submit
  onClick,
  isProcessing,
  style,
}: IProps) {
  return (
    <Button size="sm" variant={variant} type={type} onClick={onClick} disabled={isProcessing} style={style}>
      {isProcessing ? (
        <>
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>{' '}
          {label}
        </>
      ) : (
        label
      )}
    </Button>
  );
}
