import React from 'react';
import { isFunction, isString, isArray, isNil } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap';
import { LoadingTable, EmptyDataTable } from '@/components/TableHelper';

interface IProps {
  isLoading: boolean;
  tableFormat: any;
  tableResult: any;
}
export default function SimpleTable(props: IProps) {
  const location = useLocation();
  // console.log('simpleForm', props);
  // console.log('props.tableResult', props.tableResult);
  return (
    <div>
      <table className="table">
        <thead>
          {isFunction(props.tableFormat.theads) ? (
            <props.tableFormat.theads />
          ) : (
            <tr>
              {props.tableFormat.theads.map((obj: string, index: number) => {
                return <th key={index} dangerouslySetInnerHTML={{ __html: obj }} />;
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {props.isLoading && (
            <tr>
              <td colSpan={props.tableFormat.columns.length}>
                <LoadingTable />
              </td>
            </tr>
          )}
          {!props.isLoading && (!props.tableResult || props.tableResult.length === 0) && (
            <tr>
              <td colSpan={props.tableFormat.columns.length}>
                <EmptyDataTable />
              </td>
            </tr>
          )}
          {!props.isLoading &&
            props.tableResult &&
            isArray(props.tableResult) &&
            props.tableResult.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  {props.tableFormat.columns.map((col: any, index2: number) => {
                    if (isFunction(col)) {
                      const result = col(item);
                      if (isString(result)) {
                        return <td key={index2} dangerouslySetInnerHTML={{ __html: isNil(result) ? '-' : result }} />;
                      } else {
                        return <td key={index2}>{isNil(result) ? '-' : result}</td>;
                      }
                    } else if (isString(col)) {
                      if (props.tableFormat.link && col === props.tableFormat.link.column) {
                        return (
                          <td key={index2}>
                            <a
                              href={`${location.pathname}/detail/${item[props.tableFormat.link.key]}`}
                              dangerouslySetInnerHTML={{ __html: isNil(item[col]) ? '-' : item[col] }}
                            />
                          </td>
                        );
                      } else {
                        return <td key={index2} dangerouslySetInnerHTML={{ __html: isNil(item[col]) ? '-' : item[col] }} />;
                      }
                    } else {
                      return <td key={index2}>unknown</td>;
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
