import React from 'react';
import * as yup from 'yup';
import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import { PROMOTION, REGION } from '@/constants/constants';
import { checkBetween } from '@/utils/date';
import { format, parse } from 'date-fns';
import '@/styles/promotionDetail.scss';

const getStatus = (item: any) => {
  const result = checkBetween(item[PROMOTION.START_DATE], item[PROMOTION.END_DATE]);
  if (result === -1) return '적용전';
  else if (result === 0) return '적용중';
  else if (result === 1) return '만료';
  else return 'invalid status';
};

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={api.promotion}
      apiResponseInterceptor={(res: any) => {
        res.customDate = res[PROMOTION.START_DATE] + ',' + res[PROMOTION.END_DATE];
        res.status = getStatus(res);
        // 데이터 포맷 변경
        /* res.startDate = format(new Date(res[PROMOTION.START_DATE]), 'yyyy-MM-dd HH:mm:ss');
        res.endDate = format(new Date(res[PROMOTION.END_DATE]), 'yyyy-MM-dd HH:mm:ss');
         */
        res.startDate = res[PROMOTION.START_DATE].replace('Z', '');
        res.endDate = res[PROMOTION.END_DATE].replace('Z', '');
        return res;
      }}
      fieldFormat={[
        {
          keyId: 'code',
          title: '코드',
          field: DetailField.InputBuilder({
            placeholder: '적용할 코드를 입력해주세요.',
            disclaimer: '* 대소문자 구분은 없으며, 영문 또는 숫자를 조합하여 최소 5글자 이상 최대 20글자 이내로 입력 해주세요.',
          }),
          schema: yup
            .string()
            .matches(/^[0-9|a-z|A-Z]+$/, '영문 또는 숫자를 입력해주세요.')
            .min(5, '최소 5글자 이상을 입력해주세요.')
            .max(20, '최대 20글자 이내로 입력해주세요.')
            .required('적용할 코드를 입력해 주세요.'),
        },
        {
          keyId: PROMOTION.CATEGORY,
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: PROMOTION.PRODUCT_ID, // product selectbox가 선택되면 맵핑됨.
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: PROMOTION.REGION, // 다른 field와 region을 공유하기 위해 사용함.
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: PROMOTION.PRODUCT_PRICE,
          title: '적용상품',
          field: DetailField.ActivePriceSelect,
          schema: yup.string().required('적용할 상품을 선택해주세요.'),
        },
        {
          keyId: PROMOTION.SALE_AMOUNT,
          title: '할인금액',
          field: DetailField.InputBuilder({}),
          schema: yup
            .string()
            .matches(/^[0-9.]+$/, '할인금액은 숫자로만 입력해주세요.')
            .test('test1', '', function (value, obj) {
              if (value === null || value === undefined) return true;
              let productPrice = obj.parent[PROMOTION.PRODUCT_PRICE];
              productPrice = parseFloat(productPrice ? productPrice : 0);
              const floatValue = parseFloat(value);

              if (productPrice < floatValue) {
                return this.createError({ message: '할인금액은 상품가보다 작거나 같아야합니다.' });
              } else if (obj.parent.region === REGION.KR) {
                const result = productPrice - floatValue;
                if (1 <= result && result <= 999) {
                  return this.createError({ message: '1원~999원 범위 내의 결제금액은 생성할 수 없습니다. 할인금액을 변경해주세요.' });
                }
              } else if (obj.parent.region === REGION.GLOBAL) {
                const result = productPrice - floatValue;
                if (0 < result && result < 1) {
                  return this.createError({ message: '0달러 초과 1달러 미만의 결제금액은 생성할 수 없습니다. 할인금액을 변경해주세요.' });
                }
              }
              return true;
            })
            .required('할인금액을 입력해주세요.'),
        },
        {
          keyId: '',
          title: '결제금액',
          field: DetailField.PaymentPrice,
          schema: yup.string(),
        },
        {
          keyId: 'customDate',
          title: '유효기간',
          field: DetailField.DateRangeWithHour,
          schema: yup.string().required('유효기간을 입력해주세요.'),
        },
        {
          keyId: 'status',
          title: '상태',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
          schema: yup.string(),
        },
        {
          keyId: 'regDate',
          title: '등록일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
      ]}
    />
  );
}
