import React from 'react';

import { Form } from 'react-bootstrap';

interface ISelectBox {
  label: string;
  value: string;
}
interface IProps {
  list: ISelectBox[];
  defaultValue: string;
  onChange: (_: string) => void;
  style?: any;
}
export default function SelectBox(props: IProps) {
  // const [value, setValue] = React.useState(props.defaultValue || '');
  const onClickOption = (d: any) => {
    console.log('d.target.value', d.target.value);
    props.onChange(d.target.value);
  };
  return (
    <div style={props.style}>
      <Form.Control as="select" size="sm" onChange={onClickOption} defaultValue={props.defaultValue}>
        {props.list &&
          props.list.map((obj: ISelectBox, index: number) => {
            return (
              <option key={index} value={obj.value}>
                {obj.label}
              </option>
            );
          })}
      </Form.Control>
    </div>
  );
}
