import React from 'react';
import * as yup from 'yup';
import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={api.singerNumber}
      fieldFormat={[
        {
          keyId: 'id',
          title: '가수번호',
          field: DetailField.InputBuilder({ placeholder: '가수번호를 입력해주세요..' }),
          schema: yup
            .string()
            .matches(/^[0-9]+$/, '가수번호는 숫자로만 입력해주세요.')
            .required('가수번호를 입력해주세요.'),
        },
        {
          keyId: 'name',
          title: '가수명',
          field: DetailField.InputBuilder({ placeholder: '가수명을 입력해주세요.' }),
          schema: yup.string().required('가수명을 입력해주세요.'),
        },
        {
          keyId: 'regDate',
          title: 'DB 등록 일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
      ]}
    />
  );
}
