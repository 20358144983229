import React, { useState } from 'react';
import * as yup from 'yup';

import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import options from '@/constants/options';
import TVBrandButtons from '@/components/TVBrandButtons';

export default function Component(props: any) {
  return (
    <div>
      <TVBrandButtons pageType={props.pageType}></TVBrandButtons>

      <SimpleDetailModule
        pageName="PRIVACY_AGREEMENT"
        pageType={props.pageType}
        api={api.terms}
        fieldFormat={[
          {
            keyId: 'title',
            title: '제목',
            field: DetailField.InputBuilder({
              placeholder: '제목을 입력해주세요.(제목은 관리용도로만 사용됩니다.',
            }),
            schema: yup.string().required('제목을 입력해주세요.(제목은 관리용도로만 사용됩니다.)'),
          },
          {
            keyId: 'enforcementDate',
            title: '시행공고일',
            field: DetailField.DateSingle,
            schema: yup.string().required('시행공고일을 입력해주세요.'),
          },
          {
            keyId: 'koContent',
            title: '내용(국문)',
            field: DetailField.Editor,
            schema: yup.string().required('내용(국문)을 입력해주세요.'),
          },
          {
            keyId: 'enContent',
            title: '내용(영문)',
            field: DetailField.Editor,
            schema: yup.string().required('내용(영문)을 입력해주세요.'),
          },
          {
            keyId: 'regDate',
            title: '등록일시',
            field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
          },
        ]}
      />
    </div>
  );
}
