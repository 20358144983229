import React from 'react';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import ExcelUploadModal from '@/components/ExcelUploadModal';
import AlertBottom from '@/components/AlertBottom';
import api from '@/api';
import classNames from 'classnames';
import qs from 'qs';
import { Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import '@/styles/vocabulary.scss';

let queryObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });

export default function Component(props: any) {
  const location = useLocation();
  const gotoCreate = () => {
    window.location.href = `${location.pathname}/create?tab=${queryObj.tab === 'title' ? 'title' : 'singer'}`;
  };
  return (
    <>
      <SearchModule
        {...props}
        api={api.vocabulary}
        apiResponseInterceptor={(res: any) => {
          res.list.forEach((obj: any) => {
            obj.similarWords = obj.words ? obj.words.map((obj2: any) => obj2.searchWord).join(', ') : '';
          });
          return res;
        }}
        inputFormat={[
          {
            title: '키워드',
            keyId: 'keyword',
            field: SearchField.Input({
              placeholder: '대표단어, 유사단어로 검색하실 수 있습니다.',
            }),
          },
        ]}
        tableFormat={{
          theads: ['No', '대표단어', '유사단어', 'DB 등록 일시'],
          columns: [
            'id',
            (item: any) => (
              <a href={`vocabulary/detail/${item['id']}?tab=${queryObj.tab === 'title' ? 'title' : 'singer'}`}>{item['representation']}</a>
            ),
            'similarWords',
            'regDate',
          ],
          link: { column: 'representation', key: 'id' },
        }}
        customCreateButton={
          <Button size="sm" variant="primary" style={{ width: '100px' }} onClick={gotoCreate} block>
            <PlusCircle size="16" /> 신규추가
          </Button>
        }
        bottomLeftMenu={() => (
          <ExcelUploadModal api={api.vocabulary} title="어휘사전 업로드">
            <div>- Prefix NE 중 사전명 Singer와 Title 타입만 업로드됩니다.</div>
            <div>- 중복 No는 제외 후 업로드 됩니다.</div>
          </ExcelUploadModal>
        )}
        vocabularyTab={({ queryObj, take }: any) => {
          const clickCategory = (tab: string) => {
            window.location.href =
              '?' +
              qs.stringify({
                ...queryObj,
                skip: 0,
                take,
                tab,
              });
          };
          return (
            <div className="vocabulary-tabs">
              <div
                className={classNames('tab', {
                  focus: queryObj['tab'] !== 'title',
                })}
                onClick={() => {
                  clickCategory('singer');
                }}
              >
                SINGER
              </div>
              <div
                className={classNames('tab', {
                  focus: queryObj['tab'] === 'title',
                })}
                onClick={() => {
                  clickCategory('title');
                }}
              >
                Title
              </div>
            </div>
          );
        }}
      />
      <AlertBottom>
        <ul>
          <li>어휘사전은 추가분에 대해서만 엑셀 업로드가 가능합니다.</li>
          <li>엑셀 업로드시 데이터량에 따라 다소 시간이 소요될 수 있습니다.</li>
          <li>엑셀 업로드 시 No가 중복되는 경우 중복되는 가수는 제외 후 업로드 됩니다.</li>
        </ul>
      </AlertBottom>
    </>
  );
}
