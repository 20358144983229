export enum REGION {
  GLOBAL = 'GLOBAL',
  KR = 'KR',
}
export enum CATEGORY {
  KARAOKE = 'K-ARAOKE',
  WEBOSKARAOKE = 'WEBOS-K-ARAOKE',
  BIXBY = 'BIXBY',
  KYLG = 'KYLG',
}

// 관련된 key가 PromotionDetail.tsx, DetailField.tsx에 각각 존재하기 때문에 변수로 관리
export const PROMOTION = {
  CATEGORY: 'productCategory',
  PRODUCT_ID: 'productId',
  PRODUCT_PRICE: 'productPrice',
  SALE_AMOUNT: 'saleAmount',
  REGION: 'region',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

export const VOCABULARY = {
  STANDARD: 'representation',
};

export enum PROVIDER {
  IMP = 'IMP',
  PAYPAL = 'PAYPAL',
}

// 대용량 업로드
export enum JOB_TYPE {
  TEMP = 'TEMP', // 임시 업로드
  POST = 'POST', // 게시
}
export enum JOB_STATUS {
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}
export enum JOB_STEP {
  STEP0_DEFAULT = 'STEP0_DEFAULT',
  STEP1_TEMP_PROGRESS = 'STEP1_TEMP_PROGRESS',
  STEP2_TEMP_FAILED = 'STEP2_TEMP_FAILED',
  STEP3_TEMP_SUCCESS = 'STEP3_TEMP_SUCCESS',
  STEP4_POST_PROGRESS = 'STEP4_POST_PROGRESS',
  STEP5_POST_FAILED = 'STEP5_POST_FAILED',
  STEP6_POST_SUCCESS = 'STEP6_POST_SUCCESS',
}
