import React from 'react';
import SunEditor from 'suneditor-react';

// import SunEditor,{buttonList} from "suneditor-react";
/*
	buttonList.basic = basic buttons for wordprocessing
	buttonList.formatting = most tools used for formatting - This is the default option
	buttonList.complex = contains most of the buttons
*/

// buttonList.complex 을 기반으로 수정함.
const buttonList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['link' /*"image","video"*/],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  /*
  [
    "save","template"
  ]*/
];
export default function Component({ defaultValue, onChange }: any) {
  // const handleChange = (content: any) => {
  //   console.log(content); //Get Content Inside Editor
  // }
  return (
    <div>
      <SunEditor defaultValue={defaultValue} setOptions={{ height: 300, buttonList: buttonList }} onChange={onChange} />
    </div>
  );
}
