import React from 'react';

export default function Component() {
  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <div>페이지를 찾을 수 없습니다.</div>
        <a href="/">메인으로 돌아가기</a>
      </div>
    </div>
  );
}
