import React from 'react';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import ExcelUploadModal from '@/components/ExcelUploadModal';
import AlertBottom from '@/components/AlertBottom';
import api from '@/api';

export default function Component(props: any) {
  return (
    <>
      <SearchModule
        {...props}
        api={api.singerNumber}
        inputFormat={[
          {
            title: '키워드',
            keyId: 'keyword',
            field: SearchField.Input({ placeholder: '가수명, 가수번호로 검색하실 수 있습니다.' }),
          },
        ]}
        tableFormat={{
          theads: ['가수번호', '가수명', 'DB 등록 일시'],
          columns: ['id', 'name', 'regDate'],
          link: { column: 'name', key: 'id' },
        }}
        bottomLeftMenu={() => <ExcelUploadModal api={api.singerNumber} title="가수 번호 업로드" />}
      />
      <AlertBottom>
        <ul>
          <li>가수 번호는 추가분에 대해서만 엑셀 업로드가 가능합니다.</li>
          <li>엑셀 업로드시 데이터량에 따라 다소 시간이 소요될 수 있습니다.</li>
          <li>엑셀 업로드 시 가수 번호가 중복되는 경우 중복되는 가수는 제외 후 업로드 됩니다.</li>
        </ul>
      </AlertBottom>
    </>
  );
}
