import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Row, Col, InputGroup, Button, Modal, FormControl, Spinner } from 'react-bootstrap';
import { Reply } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import ProcessButton from '@/components/ProcessButton';
import CustomError from '@/errors/CustomError';
import { alertMessage } from '@/utils/apiUtil';
import TvBrandContext from '@/TvBrandContext';

interface IProps {
  pageType: any;
  formik: any;
  api: any;
  pageName?: string;
  isSong: boolean;
  songList?: any; // isSong: true인 경우만
}

export default function Component(props: IProps) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const { brand } = useContext(TvBrandContext);

  /**
   * 취소
   */
  const cancelFn = () => {
    if (!window.confirm('취소하시겠습니까? 지금까지 입력된 항목은 저장되지 않습니다.')) {
      return;
    }
    gotoList();
  };
  /**
   * 삭제
   */
  const deleteFn = async () => {
    if (!window.confirm('삭제 하시겠습니까? 해당 데이터는 즉시 삭제되며 삭제 후에는 복구 할 수 없습니다.')) {
      return;
    }
    await props.api.delete(props.pageType.id);
    gotoList();
    alert('삭제되었습니다.');
  };
  /**
   * 생성
   */
  const createFn = async () => {
    if (!(await validate())) {
      return;
    }
    try {
      setIsProcessing(true);
      let params: any = props.formik.values;

      // 예외 - 컨텐츠 관리
      if (props.isSong && props.songList && props.songList.length > 0) {
        const songIds = props.songList.map((obj: any) => obj.id);
        params.songIds = songIds;
      }
      // 예외 - 컨텐츠 관리

      // tvBrand 가 있는 경우
      if (brand) {
        params.tvBrand = brand;
      }

      await props.api.create(params, props.pageName);
      gotoList();
      alert('등록되었습니다.');
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message);
      } else {
        alertMessage(e);
      }
    } finally {
      setIsProcessing(false);
    }
  };
  /**
   * 수정
   */
  const updateFn = async () => {
    if (!(await validate())) {
      return;
    }
    try {
      setIsProcessing(true);
      let params: any = props.formik.values;

      // 예외 - 컨텐츠 관리
      if (props.isSong && props.songList && props.songList.length > 0) {
        const songIds = props.songList.map((obj: any) => obj.id);
        params.songIds = songIds;
      }
      // 예외 - 컨텐츠 관리

      await props.api.update(params, props.pageType.id, props.pageName);
      gotoList();
      alert('수정되었습니다.');
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message);
      } else {
        alertMessage(e);
      }
    } finally {
      setIsProcessing(false);
    }
  };
  const validate = async () => {
    const errors: any = await props.formik.validateForm();
    console.log('validateForm, errors', errors);
    console.log('validateForm, values', props.formik.values);
    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      alert(errors[key]);
      const elem: HTMLInputElement | null = document.querySelector(`[focus-name='${key}']`);
      if (elem) {
        elem.focus();
      } else {
        console.error('elem.focus();', 'focus-name=', key, 'elem', elem);
      }
      return false;
    } else {
      console.log('에러 없음.');
    }
    // 예외 - 컨텐츠 관리
    // 예외2 - MAIN_BANNER의 경우에는 곡이 없어도 저장되게끔 요청 (2024.02.27)
    if (props.isSong && props.songList.length < 1 && props.pageName !== 'MAIN_BANNER') {
      alert('수록곡은 최소 1곡이상 등록되어야합니다');
      const elem: HTMLInputElement | null = document.querySelector(`[focus-name='button-add-song']`);
      if (elem) elem.focus();
      return false;
    }
    return true;
  };
  const gotoList = () => {
    window.location.href = location.pathname.substring(0, location.pathname.indexOf('/', 1));
  };
  useEffect(() => {
    return () => setIsProcessing(false);
  }, []);
  return (
    <div>
      {props.pageType.mode === 'create' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <Button size="sm" variant="outline-secondary" onClick={cancelFn} style={{ width: '200px' }}>
            <Reply size="16" /> 취소
          </Button>
          <ProcessButton label="등록" onClick={createFn} isProcessing={isProcessing} style={{ width: '200px' }} />
        </div>
      )}
      {props.pageType.mode === 'detail' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <div style={{ width: '200px' }}>
            <Button size="sm" variant="outline-secondary" onClick={deleteFn} block>
              삭제
            </Button>
          </div>
          <ProcessButton label="수정" onClick={updateFn} isProcessing={isProcessing} style={{ width: '200px' }} />
        </div>
      )}
    </div>
  );
}
