import { isEmpty, isNumber, isNil } from 'lodash-es';
import { REGION } from '@/constants/constants';

export const getFormatPrice = (region: string, amount: number, defaultValue?: string) => {
  if (!isNumber(amount)) {
    console.error('amount is not number', amount);
    return 'invalid number';
  }
  if (amount === 0) {
    if (!isNil(defaultValue)) {
      return defaultValue;
    }
    return '0';
  }

  if (region === REGION.GLOBAL) {
    return '$' + amount.toLocaleString('en', { maximumFractionDigits: 10 /*style: 'currency', currency: 'USD' */ });
  } else if (region === REGION.KR) {
    return '₩' + amount.toLocaleString('en', { maximumFractionDigits: 10 /*style: 'currency', currency: 'KRW' */ });
  } else {
    return amount.toLocaleString('en', { maximumFractionDigits: 10 });
  }
};
