import React from 'react';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import ExcelUploadModal from '@/components/ExcelUploadModal';
import AlertBottom from '@/components/AlertBottom';
import api from '@/api';

export default function Component(props: any) {
  return (
    <>
      <SearchModule
        {...props}
        api={api.songInfo}
        inputFormat={[
          {
            title: '키워드',
            keyId: 'keyword',
            field: SearchField.Input({
              placeholder: '가수명, 가수번호, 곡제목으로 검색하실 수 있습니다.',
            }),
          },
        ]}
        tableFormat={{
          theads: ['곡번호', '제목', '가수', '가수영문명', '국가', 'DB 등록 일시'],
          columns: ['id', 'title', 'singer', 'singerEng', 'cCtry', 'regDate'],
          link: { column: 'title', key: 'id' },
        }}
        bottomLeftMenu={() => <ExcelUploadModal api={api.songInfo} title="곡정보 업로드" />}
      />
      <AlertBottom>
        <ul>
          <li>곡정보 데이터는 추가분에 대해서만 엑셀 업로드가 가능합니다.</li>
          <li>엑셀 업로드시 데이터량에 따라 다소 시간이 소요될 수 있습니다.</li>
          <li>엑셀 업로드 시 곡 번호가 중복되는 경우 중복되는 곡은 덮어쓰기 됩니다.</li>
        </ul>
      </AlertBottom>
    </>
  );
}
