import React from 'react';
import * as yup from 'yup';
import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import options from '@/constants/options';

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={api.songInfo}
      apiResponseInterceptor={(res: any) => {
        if (res.sKeySex === null) {
          res.sKeySex = options.NOT_SELECT;
        }
        return res;
      }}
      styleTemplate="template-songInfo"
      fieldFormat={[
        {
          keyId: 'id',
          title: '곡번호',
          field: DetailField.InputBuilder({
            placeholder: '곡번호를 입력해주세요.',
          }),
          schema: yup
            .string()
            .matches(/^[0-9]+$/, '곡번호는 숫자로만 입력해주세요.')
            .required('곡번호를 입력해주세요.'),
        },
        {
          keyId: 'singerId',
          title: '가수ID',
          field: DetailField.InputBuilder({
            placeholder: '가수ID를 입력해주세요.',
          }),
          schema: yup.string().required('가수ID를 입력해주세요.'),
        },
        {
          keyId: 'title',
          title: '제목',
          field: DetailField.InputBuilder({
            placeholder: '곡 제목을 입력해주세요.',
          }),
          schema: yup.string().required('곡 제목을 입력해주세요.'),
        },
        {
          keyId: 'singer',
          title: '가수',
          field: DetailField.InputBuilder({
            placeholder: '가수명을 입력해주세요.',
          }),
          schema: yup.string().required('가수명을 입력해주세요.'),
        },
        {
          keyId: 'singerEng',
          title: '가수영문명',
          field: DetailField.InputBuilder({
            placeholder: '가수영문명을 입력해주세요.',
          }),
          schema: yup.string().required('가수영문명을 입력해주세요.'),
        },
        {
          keyId: 'cCtry',
          title: '국가',
          field: DetailField.SelectBoxBuilder({ list: options.countryList }),
          schema: yup.string().required('곡의 국가를 선택해주세요.'),
        },
        {
          keyId: 'sKeySex',
          title: '남녀키',
          field: DetailField.RadioBuilder({ list: options.musicKeyGender }),
          schema: yup.string().required('곡의 기준키를 선택해주세요.'),
        },
        {
          keyId: 'sMKey',
          title: '남자키',
          field: DetailField.SelectBoxBuilder({ list: options.musicKeyList }),
          schema: yup.string().required('남자키를 선택해주세요.'),
        },
        {
          keyId: 'sFKey',
          title: '여자키',
          field: DetailField.SelectBoxBuilder({ list: options.musicKeyList }),
          schema: yup.string().required('여자키를 선택해주세요.'),
        },
        {
          keyId: 'sWriter',
          title: '작사가',
          field: DetailField.InputBuilder({
            placeholder: '작사가 명을 입력해주세요.',
          }),
          schema: yup.string().nullable(),
        },
        {
          keyId: 'sCompos',
          title: '작곡가',
          field: DetailField.InputBuilder({
            placeholder: '작곡가 명을 입력해주세요.',
          }),
          schema: yup.string().nullable(),
        },
        {
          keyId: 'isrc',
          title: 'isrc',
          field: DetailField.InputBuilder({
            placeholder: 'isrc 입력해주세요.',
          }),
          schema: yup.string().nullable(),
        },
        {
          keyId: 'iswc',
          title: 'iswc',
          field: DetailField.InputBuilder({
            placeholder: 'iswc 입력해주세요.',
          }),
          schema: yup.string().nullable(),
        },
        {
          keyId: 'regDate',
          title: 'DB 등록 일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
        {
          keyId: 'instFlag',
          title: '반주곡 여부',
          field: DetailField.CheckBoxBuilder({
            label: '',
          }),
        },
      ]}
    />
  );
}
