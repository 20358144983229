import jwtDecode from 'jwt-decode';

enum TOKEN {
  ACCESS_TOKEN = 'KY_ACCESS_TOKEN',
  REFRESH_TOKEN = 'KY_REFRESH_TOKEN',
  USERNAME = 'KY_USERNAME',
}
// 로그인시 사용함.
interface IToken {
  success: boolean;
  accessToken: string;
  refreshToken: string;
}
export const initToken = (result: IToken) => {
  try {
    const obj: any = jwtDecode(result.accessToken);
    // 파싱 성공하면,
    setAccessToken(result.accessToken);
    setRefreshToken(result.refreshToken);
    setUsername(obj.name || '');
    return true;
  } catch (e) {
    console.error('invalid accessToken', e);
  }
  return false;
};
export const initTokenByRefresh = (accessToken: string) => {
  try {
    const obj: any = jwtDecode(accessToken);
    // 파싱 성공하면,
    setAccessToken(accessToken);
    setUsername(obj.name || '');
    return true;
  } catch (e) {
    console.error('invalid accessToken', e);
  }
  return false;
};

export const setAccessToken = (accessToken: string) => {
  window.localStorage.setItem(TOKEN.ACCESS_TOKEN, accessToken);
};
export const getAccessToken = () => {
  return window.localStorage.getItem(TOKEN.ACCESS_TOKEN);
};
export const setRefreshToken = (refreshToken: string) => {
  window.localStorage.setItem(TOKEN.REFRESH_TOKEN, refreshToken);
};
export const getRefreshToken = () => {
  return window.localStorage.getItem(TOKEN.REFRESH_TOKEN);
};
export const setUsername = (username: string) => {
  window.localStorage.setItem(TOKEN.USERNAME, username);
};
// 헤더에 출력할 때 사용함.
export const getUsername = () => {
  return window.localStorage.getItem(TOKEN.USERNAME);
};
// 로그아웃 할 때 사용함.
export const resetToken = () => {
  setUsername('');
  setAccessToken('');
  setRefreshToken('');
};
