import React, { useState } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';

export default function FloatingInfo({ children }: any) {
  return (
    <div className="dimmed-box">
      <div className="text-box">
        <div style={{ display: 'flex' }}>
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <div style={{ marginLeft: '10px' }}>{children}</div>
        </div>
      </div>
    </div>
  );
}
