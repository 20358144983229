import { format, parse } from 'date-fns';

export const getTextFormat = (data: any, formatString: string) => {
  if (typeof data === 'string') {
    const d: Date = new Date(data + '');
    if (!isNaN(d.getTime())) {
      return format(d, formatString);
    }
    console.error('isNaN(d.getTime())', data);
    return '-';
  } else if (data instanceof Date) {
    if (!isNaN(data.getTime())) {
      return format(data, formatString);
    }
    console.error('isNaN(d.getTime())', data);
    return '-';
  }
  console.error('getTextDatetime invalid date type');
  return null; // invalid type
};
export const getTextDatetime = (data: any) => {
  return getTextFormat(data, 'yyyy-MM-dd HH:mm:ss');
};
export const getTextDateMinute = (data: any) => {
  return getTextFormat(data, 'yyyy-MM-dd HH:mm');
};
export const getTextDate = (data: any) => {
  return getTextFormat(data, 'yyyy-MM-dd');
};

/**
 * 현재기준(new Date)이
 * startDate 미만: -1
 * startDate 이상 and endDate 미만: 0
 * endDate 이상: 1
 */
export const checkBetween = (startDate: string, endDate: string, formatString?: string) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (now < start) {
    return -1;
  } else if (start <= now && now < end) {
    return 0;
  } else if (end <= now) {
    return 1;
  } else {
    return null;
  }
};
