import React from 'react';
import { isNil } from 'lodash-es';
import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import options from '@/constants/options';
import api from '@/api';
import { getTextDateMinute, checkBetween } from '@/utils/date';
import { getFormatPrice } from '@/utils/price';

const getStatus = (item: any) => {
  const result = checkBetween(item.startDate, item.endDate);
  if (result === -1) return '적용전';
  else if (result === 0) return '적용중';
  else if (result === 1) return '만료';
  else return 'invalid status';
};
export default function Component(props: any) {
  return (
    <SearchModule
      {...props}
      api={api.promotion}
      styleTemplate="template-promotion"
      inputFormat={[
        {
          title: '코드',
          keyId: 'searchCode',
          field: SearchField.Input({ placeholder: '프로모션 코드로 검색하실 수 있습니다.' }),
        },
        {
          title: '상태',
          keyId: 'status',
          field: SearchField.Radio({ list: options.promotionStatus4List }),
        },
      ]}
      tableFormat={{
        theads: ['코드', '적용카테고리', '적용상품', '할인금액', '상태', '유효기간', '등록일시'],
        columns: [
          'code',
          'productCategory',
          'productName',
          (item: any) => getFormatPrice(item.region, item.saleAmount),
          (item: any) => {
            return getStatus(item);
          },
          (item: any) => getTextDateMinute(item.startDate.replace('Z', '')) + ' ~ ' + getTextDateMinute(item.endDate.replace('Z', '')),
          'regDate',
        ],
        link: { column: 'code', key: 'id' },
      }}
    />
  );
}
