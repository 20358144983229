import React, { useState } from 'react';

import DraggableModule from '@/modules/DraggableModule';
import api from '@/api';
import TVBrandButtons from '@/components/TVBrandButtons';

export default function Component(props: any) {
  return (
    <div>
      <TVBrandButtons></TVBrandButtons>
      <DraggableModule
        pageName="KY"
        api={api.contents}
        theads={['DRAGGABLE', '카테고리명', '하위 수록곡', '등록일']}
        columns={['DRAGGABLE', 'title', 'songs', 'regDate']}
        link={{ column: 'title', key: 'id' }}
      />
    </div>
  );
}
