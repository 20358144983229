import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { get } from 'lodash-es';
import { format } from 'date-fns';
import { Container, Form, Row, Col, InputGroup, Button, Modal, FormControl } from 'react-bootstrap';
import DetailBottomButtons from '@/components/DetailBottomButtons';
import { LoadingTable, ErrorTable } from '@/components/TableHelper';
import { CardList } from 'react-bootstrap-icons';
import SongModal from '@/components/SongModal';
import { alertMessage } from '@/utils/apiUtil';

const changeDate = (obj: any, key: string) => {
  if (obj[key]) {
    try {
      obj[key] = format(new Date(obj[key]), 'yyyy-MM-dd HH:mm:ss'); // 공통
    } catch (e) {
      console.error('format e', e); // 에러나면 무시
    }
  }
};

interface IFieldFormat {
  keyId: string;
  title: string;
  field: any;
  schema?: any;
  defaultValue?: any;
}
interface IProps {
  pageName?: string;
  pageType: {
    mode: string;
    subTitle?: string;
    id: string;
  };
  tvBrand?: string;
  api: any;
  apiResponseInterceptor?: (res: any) => any;
  apiResponseDetailKey?: string;
  styleTemplate?: string;
  fieldFormat: IFieldFormat[];
  /**
   * songModal인 경우만
   */
  isSongModal?: boolean;
}
export default function SimpleDetailModule(props: IProps) {
  const history = useHistory();
  const location = useLocation();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // detail화면의 경우, api response를 전달 받은 이후에 form을 그리기 위해.
  const schemaList: any = {};
  const initialValues: any = {};

  /**
   * songModal인 경우만
   */
  const [songList, setSongList] = useState<any[]>([]);

  props.fieldFormat.forEach((obj: IFieldFormat) => {
    if (!obj.keyId) return;
    initialValues[obj.keyId] = '';
    if (obj.hasOwnProperty('defaultValue')) {
      initialValues[obj.keyId] = obj.defaultValue;
    }
    if (!obj.schema) return;
    schemaList[obj.keyId] = obj.schema;
  });
  // console.log('schemaList', schemaList);
  // console.log('initialValues', initialValues);

  const schema = yup.object(schemaList);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      console.log('onSubmit', values); // 실제로 사용되지는 않음. button submit방식을 사용하지 않음.
    },
  });
  const getDetail = async () => {
    try {
      let res: any = await props.api.detail(props.pageType.id);
      if (!res) {
        throw new Error('res is null. check api network.');
      }
      if (props.apiResponseInterceptor) {
        res = props.apiResponseInterceptor(res);
      }
      if (!res) {
        throw new Error('res is null. check apiResponseInterceptor. res should be returned');
      }
      let detail = res;
      if (props.apiResponseDetailKey) {
        detail = get(res, props.apiResponseDetailKey);
      }
      if (!detail) {
        throw new Error(`there is not res."${props.apiResponseDetailKey}"`);
      }

      changeDate(detail, 'regDate');

      /**
       * songModal인 경우만
       */
      if (props.isSongModal) {
        if (res?.songs) {
          res.songs.forEach((obj: any) => {
            obj.checked = false;
          });
          setSongList(res.songs);
        }
      }

      const mergedValues = {
        ...initialValues,
        ...detail,
      };
      formik.setValues(mergedValues);
    } catch (e) {
      setIsError(true);
      alertMessage(e);
    } finally {
      setIsLoading(false);
    }
  };
  const gotoList = () => {
    if (window.confirm('목록으로 돌아가시겠습니까? 변경된 내용은 저장되지 않습니다.')) {
      history.push(location.pathname.substring(0, location.pathname.indexOf('/', 1)));
    }
  };
  const getTitle = (obj: IFieldFormat) => {
    if (schemaList[obj.keyId] && schemaList[obj.keyId]?.exclusiveTests?.required === true) {
      return obj.title + `&nbsp;<span class="text-required">*</span>`;
    }
    return obj.title;
  };
  React.useEffect(() => {
    if (props.pageType.mode === 'detail') {
      getDetail();
    } else {
      setIsLoading(false);
    }
  }, []);
  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorTable />;
  }
  return (
    <div className="detail-box">
      {props.pageType.mode === 'detail' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
        >
          <Button size="sm" variant="outline-secondary" onClick={gotoList}>
            <CardList size="20" color="black" /> <span style={{ marginLeft: '10px' }}>목록</span>
          </Button>
        </div>
      )}
      {
        // songModal인 경우만
        props.isSongModal && (
          <div className="h2-title" style={{ margin: '10px 0 10px 0' }}>
            기본정보
          </div>
        )
      }
      <div className="detail-section" style={{ marginTop: '10px' }}>
        <div className={`detail-form ${props.styleTemplate}`}>
          {props.fieldFormat
            .filter((obj: IFieldFormat) => obj.field)
            .map((obj: IFieldFormat, index: number) => {
              return <obj.field key={index} formik={formik} keyId={obj.keyId} title={getTitle(obj)} />;
            })}
        </div>
      </div>
      {
        // songModal인 경우만
        props.isSongModal && (
          <div style={{ marginTop: '30px' }}>
            <SongModal data={songList} setParentData={setSongList} />
          </div>
        )
      }
      <DetailBottomButtons
        pageType={props.pageType}
        formik={formik}
        api={props.api}
        isSong={props.isSongModal ? true : false}
        songList={props.isSongModal ? songList : null}
        pageName={props.pageName}
      />
    </div>
  );
}
