import React, { useContext, useState } from 'react';
import * as yup from 'yup';

import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import TVBrandButtons from '@/components/TVBrandButtons';
import TvBrandContext from '@/TvBrandContext';
import ExcelUploadModal from '@/components/ExcelUploadModal';

export default function Component(props: any) {
  const { brand } = useContext(TvBrandContext);

  return (
    <div>
      <TVBrandButtons pageType={props.pageType}></TVBrandButtons>
      <SimpleDetailModule
        isSongModal={true}
        pageName={brand === 'samsung' ? 'HOTANDNEW' : 'CHART'}
        pageType={props.pageType}
        api={api.contents}
        fieldFormat={[
          {
            keyId: 'title',
            title: '카테고리명',
            field: DetailField.InputCategory,
            schema: yup.string().required('카테고리명을 입력해주세요.'),
          },
          {
            keyId: 'regDate',
            title: '등록일시',
            field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
          },
        ]}
      />

      {props.pageType.mode === 'detail' && (
        <div style={{ paddingTop: 10 }}>
          <ExcelUploadModal api={api.contents} title="엑셀 업로드" pageName="CATEGORY_HOT" pageType={props.pageType} />
        </div>
      )}
    </div>
  );
}
