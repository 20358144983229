import React, { useState } from 'react';
import { Container, Form, Row, Col, InputGroup, Button, Modal, Spinner } from 'react-bootstrap';
import { CloudUpload, CloudDownload, ExclamationCircle } from 'react-bootstrap-icons';
import ProcessButton from '@/components/ProcessButton';
import FloatingInfo from '@/components/FloatingInfo';
import { JOB_TYPE, JOB_STATUS, JOB_STEP } from '@/constants/constants';
import { alertMessage, pollingApi } from '@/utils/apiUtil';
import '@/styles/excelUploadModal.scss';

const getStep = (jobStatus: JOB_STATUS) => {
  if (jobStatus === undefined) {
    return JOB_STEP.STEP6_POST_SUCCESS;
  } else if (jobStatus === JOB_STATUS.PROGRESS) {
    return JOB_STEP.STEP4_POST_PROGRESS;
  } else if (jobStatus === JOB_STATUS.FAILED) {
    return JOB_STEP.STEP5_POST_FAILED;
  } else if (jobStatus === JOB_STATUS.SUCCESS) {
    return JOB_STEP.STEP6_POST_SUCCESS;
  }
  throw new Error('invalid type:' + jobStatus);
};

/**
 * 임시 업로드 없이, 실시간 업로드 후 즉시 게시
 */
interface IProps {
  api: any;
  title: string;
  pageName?: string;
  pageType?: {
    mode: string;
    subTitle?: string;
    id: string;
  };
  children?: any;
}
export default function Component({ api, title, pageName, pageType, children }: IProps) {
  const ref = React.useRef<HTMLInputElement>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [step, setStep] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [currentFilename, setCurrentFilename] = useState('');

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = async () => {
    setShow(true);

    // 상태 체크
    try {
      console.log('%c 🚈: pageType ', 'font-size:16px;background-color:#a47e24;color:white;', pageType);

      const res = await api.getJobCurrentType(pageName);
      if (!res) {
        throw new Error('res is null');
      }
      setCurrentFilename(res.filename);
      setStep(getStep(res.status));
      // 현재 상태에 따라서 폴링 결정
      if (res.status === JOB_STATUS.PROGRESS) {
        try {
          setError(false);
          const pollingResult = await pollingApi(api.getJobCurrentType, pageName);
          if (!pollingResult) {
            throw new Error('pollingResult res is null');
          }
          setStep(getStep(pollingResult.status));
          if (pollingResult.status === JOB_STATUS.FAILED) {
            throw new Error('JOB_STATUS.FAILED');
          }
        } catch (e) {
          alertMessage(e);
          setError(true);
        }
      }
    } catch (e) {
      alertMessage(e);
    }
  };
  const uploadFn = async () => {
    try {
      if (!ref || !ref.current) {
        throw new Error('ref is null');
      }
      const files = ref.current.files;
      if (!files || files.length < 1) {
        // throw new Error('files is null');
        alert('업로드할 파일을 선택해주세요');
        return;
      }
      if (
        !window.confirm(
          '1. 파싱 후 DB에 입력하는 작업의 특성상, 입력해야할 항목이 많은 경우 오랜시간이 소요 될 수도 있습니다.\n2. 중복된 데이터는 제외 후 업로드 됩니다.\n3. 중복된 곡 번호의 경우에는 덮어쓰기 됩니다.\n계속하시겠습니까?',
        )
      ) {
        return;
      }
      setError(false);
      setIsProcessing(true);
      const formData = new FormData();

      formData.append('excel', files[0]);

      if (pageType && pageName) {
        formData.append('targetId', pageType.id);
        formData.append('dataType', pageName);
      }

      const response: any = await api.upload(formData);
      if (!response) {
        throw new Error('response is null');
      }

      setCurrentFilename(response.filename);
      setStep(JOB_STEP.STEP4_POST_PROGRESS);
      const pollingResult = await pollingApi(api.getJobCurrentType, pageName);
      if (!pollingResult) {
        throw new Error('pollingResult res is null');
      }

      setStep(getStep(pollingResult.status));
      if (pollingResult.status === JOB_STATUS.FAILED) {
        throw new Error('JOB_STATUS.FAILED');
      }

      // success
      setShow(false);
      window.location.reload();
      alert('파일 업로드가 완료되었습니다.');
    } catch (e) {
      alertMessage(e, '파일 업로드에 실패하였습니다.');
      setError(true);
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <div>
      <ProcessButton label="엑셀업로드" variant="primary" onClick={handleShow} isProcessing={isProcessing} />
      <Modal
        animation={false} // Warning: findDOMNode is deprecated in StrictMode.
        show={show}
        onHide={handleClose}
        // backdrop="static" // 밖의 영역을 클릭시 modal이 종료되지 않게
        keyboard={false}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ textAlign: 'right' }}>
              <a href={api.sampleExcel()} target="_blank" rel="noreferrer">
                <CloudDownload size="16" /> 엑셀 샘플 다운로드
              </a>
            </div>
            <div className="modal-upload-form">
              <div className="item">
                <div className="item-title">업로드</div>
                <div className="item-field">
                  <Form.File.Input ref={ref} accept=".xls, .xlsx" disabled={isProcessing} />
                  <div className="text-disclaimer">
                    <div>- xls, xlsx 파일 확장자만 업로드 가능합니다.</div>
                    <div>{children}</div>
                    {step === JOB_STEP.STEP5_POST_FAILED && (
                      <div>
                        <ExclamationCircle size="14" color="red" />{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          &nbsp;최근에 실행한 파일({currentFilename})의 업로드 요청이 실패했습니다. 엑셀 양식을 다시 한번 확인해주세요.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <ProcessButton label="게시" variant="primary" onClick={uploadFn} isProcessing={isProcessing} style={{ width: '200px' }} />
            </div>
          </div>
          {
            // 화면 진입시
            step === '' && <FloatingInfo>조회중입니다.</FloatingInfo>
          }
          {!error && step === JOB_STEP.STEP4_POST_PROGRESS && <FloatingInfo>현재 업로드 중입니다.</FloatingInfo>}
        </Modal.Body>
      </Modal>
    </div>
  );
}
