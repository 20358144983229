import React, { useState } from 'react';
import { Tabs, Tab, Button, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { isEmpty, isNumber } from 'lodash-es';
import api from '@/api';
import SimpleTable from '@/components/SimpleTable';
import { add, differenceInSeconds } from 'date-fns';
import { getTextDateMinute, getTextDatetime } from '@/utils/date';
import { LoadingTable, ErrorTable } from '@/components/TableHelper';
import { getFormatPrice } from '@/utils/price';
import { CATEGORY, REGION, PROVIDER } from '@/constants/constants';
import ApiButton from '@/components/ApiButton';
import { alertMessage } from '@/utils/apiUtil';
import '@/styles/memberDetail.scss';
import { useLocation } from 'react-router';
import qs from 'qs';

const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
let currentSearchTvBrand = queryString.tvBrand;

export default function Component(props: any) {
  const [isError, setIsError] = useState<boolean>(false);
  const [isError2, setIsError2] = useState<boolean>(false);
  const [basic, setBasic] = useState<any>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [productPayments, setProductPayments] = useState<any>(null);
  const [category, setCategory] = useState<CATEGORY>(currentSearchTvBrand === 'LG' ? CATEGORY.WEBOSKARAOKE : CATEGORY.KARAOKE);

  const getDetail = async () => {
    try {
      const res: any = await api.member.detailBasic(props.pageType.id);
      if (!res) {
        throw new Error('res is null');
      }
      setBasic(res);
    } catch (e) {
      setIsError(true);
      console.error('e', e);
    } finally {
    }
  };
  const getDetailProduct = async (category: CATEGORY) => {
    setIsError2(false);
    try {
      const res: any = await api.member.detailProduct(props.pageType.id, category);
      if (res?.subscription) {
        setSubscription(res.subscription);
      } else {
        setSubscription(null);
      }
      if (res?.productPayments) {
        setProductPayments(res.productPayments);
      } else {
        setProductPayments(null);
      }
    } catch (e) {
      setIsError2(true);
      console.error('e', e);
    }
  };

  const getNextPaymentDatetime = (subscription: any) => {
    let prefix = '';
    let key = null;
    if (subscription.durationType === 'HOUR') {
      prefix = '시간';
      key = 'hours';
    } else if (subscription.durationType === 'DAY') {
      prefix = '일';
      key = 'days';
    } else if (subscription.durationType === 'MONTH') {
      prefix = '개월';
      key = 'months';
    } else if (subscription.durationType === 'YEAR') {
      prefix = '년';
      key = 'years';
    } else {
      console.error('invalid subscription.durationType');
    }
    if (key) {
      const result = add(new Date(subscription.paymentDate), {
        [key]: subscription.duration,
      });
      return `${subscription.duration}${prefix} 자동결제(${getTextDateMinute(result)})`;
    }
    return 'invalid subscription.durationType';
  };
  const refundFn = async (item: any) => {
    if (
      !window.confirm(
        '결제 취소하시겠습니까? 고객에게는 결제금액 전액이 환불되며, 결제 취소상태로 변경됩니다. 단 PG사의 응답은 지연될 수 있으며, 실제 취소되기까지는 영업일로 최대 7일까지 소요될 수 있습니다.',
      )
    ) {
      return;
    }
    let provider = null;
    if (item.region === REGION.KR) {
      provider = PROVIDER.IMP;
    } else if (item.region === REGION.GLOBAL) {
      provider = PROVIDER.PAYPAL;
    }
    if (!provider) {
      alert('invalid region');
      return;
    }
    try {
      await api.member.refund({
        provider,
        paymentId: item.paymentId,
        subscription: item.subscription,
      });
      alert('취소되었습니다.');
      window.location.reload();
    } catch (e) {
      alertMessage(e);
    }
  };
  const cancelAutoPayment = async (subscription: any) => {
    if (!window.confirm('자동결제를 해지하시겠습니까? 기결제된 상품은 취소되지 않으며, 기결 제된 상품은 별도로 환불절차를 거쳐야합니다.')) {
      return;
    }
    let provider = null;
    if (subscription.region === REGION.KR) {
      provider = PROVIDER.IMP;
    } else if (subscription.region === REGION.GLOBAL) {
      provider = PROVIDER.PAYPAL;
    }
    if (!provider) {
      alert('invalid region');
      return;
    }
    try {
      await api.member.unsubscription({
        provider,
        customerId: subscription.customerId,
      });
      alert('해지되었습니다.');
      window.location.reload();
    } catch (e) {
      alertMessage(e);
    }
  };
  const calcPaymentAmount = (item: any) => {
    const paymentAmount = (isNumber(item.productAmount) ? item.productAmount : 0) - (isNumber(item.saleAmount) ? item.saleAmount : 0);
    if (paymentAmount < 0) {
      return 0;
    }
    return paymentAmount;
  };
  React.useEffect(() => {
    getDetail();
    getDetailProduct(category);
  }, []);
  return (
    <>
      <div className="h2-title" style={{ margin: '10px 0 10px 0' }}>
        기본정보
      </div>
      {isError && <ErrorTable />}
      {basic && (
        <div className="member-form">
          <div className="item">
            <div className="item-title">이메일</div>
            <div className="item-field" style={{ display: 'block', padding: '4px 0px 4px 20px' }}>
              <div>{basic.email || '-'}</div>
              <div style={{ marginTop: '4px' }}>
                <ApiButton
                  label="인증메일 재발송"
                  variant="info"
                  style={{ marginRight: '4px', fontSize: '10px' }}
                  api={() => api.member.sendVerificationEmail(props.pageType.id)}
                  callbackMessage="[인증메일 재발송]이 처리되었습니다."
                />
                <ApiButton
                  label="변경메일 발송"
                  variant="info"
                  style={{ marginRight: '4px', fontSize: '10px' }}
                  api={() => api.member.sendPasswordEmail(props.pageType.id)}
                  callbackMessage="[PW 변경메일 발송]이 처리되었습니다."
                />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="item-title">국적</div>
            <div className="item-field">{basic.country || '-'}</div>
          </div>
          <div className="item">
            <div className="item-title">K-araoke 활성상품</div>
            <div className="item-field">{basic.kAraokeUserProduct || basic.webosKaraokeUserProduct || '-'}</div>
          </div>
          <div className="item">
            <div className="item-title">금영노래방 활성상품</div>
            <div className="item-field">{basic.bixbyUserProduct || '-'}</div>
          </div>
          <div className="item">
            <div className="item-title">등록일</div>
            <div className="item-field">{getTextDatetime(basic.regDate)}</div>
          </div>
          <div className="item">
            <div className="item-title">최근접속일</div>
            <div className="item-field">{getTextDatetime(basic.loginDate)}</div>
          </div>
        </div>
      )}

      <div className="member-tabs">
        <div
          className={classNames('tab', {
            focus: category === CATEGORY.KARAOKE,
          })}
          onClick={() => {
            setCategory(CATEGORY.KARAOKE);
            getDetailProduct(CATEGORY.KARAOKE);
          }}
        >
          삼성 K-araoke 결제내역
        </div>
        <div
          className={classNames('tab', {
            focus: category === CATEGORY.WEBOSKARAOKE,
          })}
          onClick={() => {
            setCategory(CATEGORY.WEBOSKARAOKE);
            getDetailProduct(CATEGORY.WEBOSKARAOKE);
          }}
        >
          LG K-araoke 결제내역
        </div>
        <div
          className={classNames('tab', { focus: category === CATEGORY.BIXBY })}
          onClick={() => {
            setCategory(CATEGORY.BIXBY);
            getDetailProduct(CATEGORY.BIXBY);
          }}
        >
          빅스비 결제내역
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="h2-title" style={{ margin: '20px 0 10px 0' }}>
          자동결제 신청내역
        </div>
        {subscription && <ApiButton label="자동결제 해지" variant="info" api={() => cancelAutoPayment(subscription)} />}
      </div>
      {isError2 && <ErrorTable />}
      {!isError2 && (
        <>
          {!subscription && <div className="empty-auto-payment">자동결제 신청내역이 없습니다.</div>}
          {subscription && (
            <div className="member-form">
              <div className="item">
                <div className="item-title">상품명</div>
                <div className="item-field">{subscription.productName}</div>
              </div>
              <div className="item">
                <div className="item-title">결제수단</div>
                <div className="item-field">
                  {subscription.region === REGION.GLOBAL ? '페이팔' : subscription.region === REGION.KR ? '신용카드' : 'invalid region'}
                </div>
              </div>
              <div className="item">
                <div className="item-title">상품금액</div>
                <div className="item-field">{getFormatPrice(subscription.region, subscription.amount)}</div>
              </div>
              <div className="item">
                <div className="item-title">프로모션</div>
                <div className="item-field">
                  회차할인:{' '}
                  {subscription.promotion
                    ? `${subscription.promotionCycle}회차 까지 ${getFormatPrice(subscription.region, subscription.promotion)}할인`
                    : '-'}
                </div>
              </div>
              <div className="item">
                <div className="item-title">결제주기(다음예정일)</div>
                <div className="item-field">{getNextPaymentDatetime(subscription)}</div>
              </div>
              <div className="item">
                <div className="item-title">최근결제</div>
                <div className="item-field">{subscription.success === true ? '정상결제' : `결제실패(${subscription.failedReason})`}</div>
              </div>
            </div>
          )}
          <div className="h2-title" style={{ margin: '20px 0 10px 0' }}>
            결제내역
          </div>
          <div className="detail-section">
            <SimpleTable
              isLoading={false}
              tableFormat={{
                theads: [
                  '상품명',
                  '상품금액',
                  '결제방식<br/>(결제수단)',
                  '프로모션',
                  '프로모션금액',
                  '결제금액<br/>(주문번호)',
                  '결제일',
                  '유효기간',
                  '사용여부',
                  '취소',
                ],
                columns: [
                  'productName',
                  (item: any) => getFormatPrice(item.region, item.productAmount),
                  'payMethod',
                  'promotionCode',
                  (item: any) => getFormatPrice(item.region, item.saleAmount, '-'),
                  (item: any) => {
                    return getFormatPrice(item.region, item.amount) + `<br/>(${item.displayPaymentId})`;
                  },
                  (item: any) => getTextDatetime(item.paymentDate),
                  (item: any) => getTextDatetime(item.productStartDate) + ' ~ ' + getTextDatetime(item.productEndDate),
                  (item: any) => (item.used ? 'Y' : 'N'),
                  (item: any) => {
                    try {
                      // 결제일로부터 6개월 이내인 경우 결제취소 가능
                      const month6BeforeFromNow = add(new Date(), {
                        months: -6,
                      });
                      const diffSeconds = differenceInSeconds(new Date(item.paymentDate), month6BeforeFromNow);
                      // console.log('month6FromNow', month6FromNow, 'paymentDate', new Date(item.paymentDate), 'diffSeconds', diffSeconds);
                      if (item.cancelled) {
                        return <div>취소완료</div>;
                      } else if (item.amount === 0) {
                        return <div>취소불가</div>;
                      } else if (subscription && subscription.paymentDate && item.productStartDate) {
                        const subDate = new Date(subscription.paymentDate);
                        const itemDate = new Date(item.productStartDate);
                        if (subDate.getFullYear() === itemDate.getFullYear() && subDate.getMonth() === itemDate.getMonth()) {
                          return <div>취소불가</div>; // 자동결제 해지를 먼저 해야 한다.
                        }
                      } else if (diffSeconds > 0) {
                        // 결제후 6개월 이내
                        return <ApiButton label="결제취소" variant="info" api={() => refundFn(item)} />;
                      }
                      return <div>취소불가</div>;
                    } catch (e) {
                      console.error('cancel button error', e);
                      return <div>error</div>;
                    }
                  },
                ],
              }}
              tableResult={productPayments}
            />
          </div>
        </>
      )}
    </>
  );
}
