import { createContext, useEffect, useState } from 'react';

const TvBrandContext = createContext({
  brand: '',
  setBrand: (newBrand: string) => {},
});

function TvBrandProvider(props: any) {
  const [brand, setBrand] = useState(localStorage.getItem('brand') || 'samsung');

  useEffect(() => {
    const storedTvBrand = localStorage.getItem('brand');
    if (storedTvBrand) {
      setBrand(storedTvBrand);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('brand', brand);
  }, [brand]);

  const value = {
    brand,
    setBrand: (newBrand: string) => setBrand(newBrand),
  };

  return <TvBrandContext.Provider value={value}>{props.children}</TvBrandContext.Provider>;
}

export { TvBrandProvider };
export default TvBrandContext;
