import React, { useState } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { CloudDownload, ExclamationCircle } from 'react-bootstrap-icons';
import AlertBottom from '@/components/AlertBottom';
import ProcessButton from '@/components/ProcessButton';
import { JOB_TYPE, JOB_STATUS, JOB_STEP } from '@/constants/constants';
import FloatingInfo from '@/components/FloatingInfo';
import { alertMessage, pollingApi } from '@/utils/apiUtil';

/**
 * 상태
 * 운영중인 파일이 없음 | 운영중인 파일이 있음 | 임시 업로드 중 | 임시 업로드 실패 | 임시 업로드 완료 | 게시중 | 게시 실패 | 게시완료(=운영중인 파일이 있음)
 */
const getStep = (jobType: JOB_TYPE, jobStatus: JOB_STATUS) => {
  if (jobType === undefined && jobStatus === undefined) {
    return JOB_STEP.STEP0_DEFAULT;
  } else if (jobType === JOB_TYPE.TEMP && jobStatus === JOB_STATUS.CANCELED) {
    return JOB_STEP.STEP0_DEFAULT;
  } else if (jobType === JOB_TYPE.TEMP && jobStatus === JOB_STATUS.PROGRESS) {
    return JOB_STEP.STEP1_TEMP_PROGRESS;
  } else if (jobType === JOB_TYPE.TEMP && jobStatus === JOB_STATUS.FAILED) {
    return JOB_STEP.STEP2_TEMP_FAILED;
  } else if (jobType === JOB_TYPE.TEMP && jobStatus === JOB_STATUS.SUCCESS) {
    return JOB_STEP.STEP3_TEMP_SUCCESS;
  } else if (jobType === JOB_TYPE.POST && jobStatus === JOB_STATUS.PROGRESS) {
    return JOB_STEP.STEP4_POST_PROGRESS;
  } else if (jobType === JOB_TYPE.POST && jobStatus === JOB_STATUS.FAILED) {
    return JOB_STEP.STEP5_POST_FAILED;
  } else if (jobType === JOB_TYPE.POST && jobStatus === JOB_STATUS.SUCCESS) {
    return JOB_STEP.STEP6_POST_SUCCESS;
  }
  throw new Error('invalid type:' + jobType + ',' + jobStatus);
};

interface IComponent {
  title: string;
  api: any;
}
export default function Component(props: IComponent) {
  const { title, api } = props;
  const ref = React.useRef<HTMLInputElement>(null);
  const [inputFile, setInputFile] = useState(''); // input file
  const [isProcess, setIsProcess] = useState<boolean>(false);
  // getDetail
  const [operatedFilename, setOperatedFilename] = useState<string | null>(null);

  const [jobId, setJobId] = useState('');
  const [currentFilename, setCurrentFilename] = useState('');
  const [step, setStep] = useState('');

  const [error, setError] = useState<boolean>(false);

  const getDetail = async () => {
    try {
      const res: any = await api.getFilename();
      if (res && res.filename) {
        setOperatedFilename(res.filename);
      }
    } catch (e) {
      alertMessage(e);
    }
  };
  const checkCurrentStatus = async () => {
    try {
      const res: any = await api.getJobCurrentType();
      console.log('checkCurrentStatus res', res);
      if (!res) {
        throw new Error('res is null');
      }
      setJobId(res.id);
      setCurrentFilename(res.filename);
      setStep(getStep(res.jobType, res.status));

      // 현재 상태에 따라서 폴링 결정
      if (res.status === JOB_STATUS.PROGRESS) {
        try {
          setError(false);
          const pollingResult = await pollingApi(api.getJobCurrentType);
          if (!pollingResult) {
            throw new Error('pollingResult res is null');
          }
          setJobId(pollingResult.jobId);
          setStep(getStep(pollingResult.jobType, pollingResult.status));
          if (pollingResult.status === JOB_STATUS.FAILED) {
            throw new Error('JOB_STATUS.FAILED');
          }
        } catch (e) {
          alertMessage(e);
          setError(true);
        }
      }
    } catch (e) {
      alertMessage(e);
    }
  };
  const uploadTempFile = async () => {
    try {
      if (!ref || !ref.current) {
        alert('ref is null');
        return;
      }
      const files = ref.current.files;
      if (!files || files.length < 1) {
        alert('업로드할 파일을 선택해주세요');
        return;
      }
      setError(false);
      setIsProcess(true);

      const formData = new FormData();
      formData.append('excel', files[0]);
      const response: any = await api.uploadTempFile(formData);
      if (!response) {
        throw new Error('upload response is null');
      }
      if (!response.jobId || !response.filename) {
        throw new Error('upload response is invalid. ' + JSON.stringify(response));
      }
      setJobId(response.jobId);
      setCurrentFilename(response.filename);
      setStep(getStep(JOB_TYPE.TEMP, JOB_STATUS.PROGRESS));

      const pollingResult = await pollingApi(api.getJobCurrentType);
      if (!pollingResult) {
        throw new Error('pollingResult res is null');
      }
      setJobId(pollingResult.jobId);
      setStep(getStep(pollingResult.jobType, pollingResult.status));
      if (pollingResult.status === JOB_STATUS.FAILED) {
        // 업로드를 위해 form.file.input을 사용했다가, 상태가 PROGRESS로 바뀌면서 component가 사라짐.
        // 화면 상태가 바뀌면서 form.file.input 컴포넌트를 여러 상황에 공유하면서 에러 발생. value값 매칭.
        // 에러 메시지: Failed to set the 'value' property on 'HTMLInputElement': This input element accepts a filename, which may only be programmatically set to the empty string.
        // ==> 해결: 상태가 바뀌어도 form.file.input은 그대로 유지(disabled처리만 추가함.)
        alert('임시 업로드에 실패하였습니다.\nJOB_STATUS.FAILED');
        window.location.reload();
        return;
      }
      alert('파일이 정상적으로 임시 DB에 업로드 되었습니다.\n운영 반영을 원하시면 게시 요청 버튼을 눌러 게시해주세요.');
    } catch (e) {
      alertMessage(e, `임시 업로드에 실패하였습니다.`);
      setError(true);
    } finally {
      setIsProcess(false);
    }
  };
  const operateFn = async () => {
    try {
      setError(false);
      setIsProcess(true);
      const response: any = await api.operate(currentFilename);
      if (!response) {
        throw new Error('response is null');
      }
      setStep(getStep(JOB_TYPE.POST, JOB_STATUS.PROGRESS));

      const pollingResult = await pollingApi(api.getJobCurrentType);
      if (!pollingResult) {
        throw new Error('pollingResult res is null');
      }

      if (pollingResult.status === JOB_STATUS.SUCCESS) {
        alert('음악 추천 DATA가 운영계에 정상적으로 게시되었습니다.\n실 반영은 매시각 정각에 업데이트 됩니다.');
        window.location.reload();
      } else if (pollingResult.status === JOB_STATUS.FAILED) {
        alert('운영 게시에 실패하였습니다.\nJOB_STATUS.FAILED');
        window.location.reload();
        return;
      }
    } catch (e) {
      alertMessage(e, `운영 게시에 실패하였습니다.`);
      setError(true);
    } finally {
      setIsProcess(false);
    }
  };
  const cancelFn = async () => {
    try {
      setIsProcess(true);
      const res = await api.cancel(jobId);
      if (!res) {
        throw new Error('res is null');
      }
      if (res.success !== true) {
        throw new Error('res.success is true');
      }
      window.location.reload();
    } catch (e) {
      alertMessage(e);
    } finally {
      setIsProcess(false);
    }
  };
  React.useEffect(() => {
    getDetail();
    checkCurrentStatus();
  }, []);
  return (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <div>
        <div className="h2-title">{title}</div>
        <div style={{ textAlign: 'right', marginBottom: '4px' }}>
          <a href={api.sampleExcel()} target="_blank" rel="noreferrer">
            <CloudDownload size="16" /> 엑셀 샘플 다운로드
          </a>
        </div>
        <div className="detail-section">
          <div className="detail-form">
            <div className="item" style={{ height: '48px' }}>
              <div className="item-title">현재 운영중 파일</div>
              <div className="item-field">{operatedFilename ? operatedFilename : '현재 운영중인 파일이 없습니다.'}</div>
            </div>
            <div className="item">
              <div className="item-title">업로드</div>
              <div className="item-field">
                {
                  // 임시 업로드 완료
                  step === JOB_STEP.STEP3_TEMP_SUCCESS && (
                    <div>
                      <div>{currentFilename}</div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '4px',
                        }}
                      >
                        <ExclamationCircle size="14" color="black" />
                        <span style={{ fontWeight: 'bold' }}>
                          &nbsp;파일이 정상적으로 임시 DB에 업로드 되었습니다. 운영 반영을 원하시면 게시 요청 버튼을 눌러 게시해주세요.
                        </span>
                      </div>
                    </div>
                  )
                }
                {
                  // 게시 진행중
                  step === JOB_STEP.STEP4_POST_PROGRESS && <div>{currentFilename}</div>
                }
                {
                  // DEFAULT
                  (step === JOB_STEP.STEP0_DEFAULT ||
                    // 임시 업로드 진행중
                    step === JOB_STEP.STEP1_TEMP_PROGRESS ||
                    // 임시 업로드 실패. 다시 업로드 할 수 있음.
                    step === JOB_STEP.STEP2_TEMP_FAILED ||
                    // 게시 실패. 다시 업로드 할 수 있음.
                    step === JOB_STEP.STEP5_POST_FAILED ||
                    // 게시 성공. 새로 업로드 할 수 있음.
                    step === JOB_STEP.STEP6_POST_SUCCESS) && (
                    <>
                      <Form.File.Input
                        ref={ref}
                        accept=".xls, .xlsx"
                        value={inputFile}
                        onChange={(e: any) => {
                          setInputFile(e.target.value);
                        }}
                        disabled={isProcess || step === JOB_STEP.STEP1_TEMP_PROGRESS}
                      />
                      <div className="text-disclaimer">xls, xlsx 파일 확장자만 업로드 가능합니다.</div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '4px',
                        }}
                      >
                        {step === JOB_STEP.STEP2_TEMP_FAILED && (
                          <>
                            <ExclamationCircle size="14" color="red" />{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              &nbsp;최근에 실행한 파일({currentFilename})의 업로드 요청이 실패했습니다. 엑셀 양식을 다시 한번 확인해주세요.
                            </span>
                          </>
                        )}
                        {step === JOB_STEP.STEP5_POST_FAILED && (
                          <>
                            <ExclamationCircle size="14" color="red" />{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              &nbsp;최근에 실행한 파일({currentFilename})의 게시 요청이 실패했습니다.
                            </span>
                          </>
                        )}
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        {/* 버튼 */}
        {
          // 임시 업로드 진행중
          step === JOB_STEP.STEP1_TEMP_PROGRESS && <></>
        }
        {
          // 임시 업로드 완료
          step === JOB_STEP.STEP3_TEMP_SUCCESS && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <ProcessButton
                label="취소"
                variant="outline-secondary"
                onClick={cancelFn}
                isProcessing={isProcess}
                style={{ width: '200px' }}
              />
              <ProcessButton label="게시 요청" variant="primary" onClick={operateFn} isProcessing={isProcess} style={{ width: '200px' }} />
            </div>
          )
        }
        {
          // 게시 진행중
          step === JOB_STEP.STEP4_POST_PROGRESS && <></>
        }
        {
          // DEFAULT
          (step === JOB_STEP.STEP0_DEFAULT ||
            // 임시 업로드 실패
            step === JOB_STEP.STEP2_TEMP_FAILED ||
            // 게시 실패
            step === JOB_STEP.STEP5_POST_FAILED ||
            // 게시 성공. 새로 업로드 할 수 있음.
            step === JOB_STEP.STEP6_POST_SUCCESS) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <ProcessButton
                label="임시 업로드"
                variant="outline-primary"
                onClick={uploadTempFile}
                isProcessing={isProcess}
                style={{ width: '200px' }}
              />
            </div>
          )
        }
        <AlertBottom>
          <ul>
            <li>음악 추천 DATA는 인기순위가 반영 되는 특성상 전체 데이터 업로드 방식으로 진행됩니다.</li>
            <li>5만여 개의 데이터를 파싱 후 DB에 기입하는 형태의 특성상 실제 반영까지는 시간이 소요됩니다.</li>
            <li style={{ color: 'red', fontWeight: 'bold' }}>
              운영서버에서 업로드 에러가 나는 경우 서비스에 치명적인 영향이 있을 수 있습니다. 반드시 테스트 서버에서 확인해 보신 후 업로드
              하시길 부탁드립니다.
            </li>
          </ul>
        </AlertBottom>
      </div>
      {
        // 화면 진입시
        step === '' && <FloatingInfo>조회중입니다.</FloatingInfo>
      }
      {/* // PROGRESS 인 경우 화면 block */}
      {
        // 임시 업로드 진행중
        !error && step === JOB_STEP.STEP1_TEMP_PROGRESS && (
          <FloatingInfo>
            {currentFilename}
            <br />
            파일 업로드 중입니다.
            <br />
            최대 10분가량 소요될 수 있습니다.
            <br />
            현재 화면을 벗어나 다른작업을 진행하셔도 무방합니다. 단 완료 후 게시 요청 버튼을 눌러야 운영계에 반영됩니다.
          </FloatingInfo>
        )
      }
      {
        // 게시 진행중
        !error && step === JOB_STEP.STEP4_POST_PROGRESS && (
          <FloatingInfo>
            {currentFilename}
            <br />
            게시 진행중입니다.
          </FloatingInfo>
        )
      }
    </div>
  );
}
