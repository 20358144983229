import React from 'react';
import * as yup from 'yup';
import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import { VOCABULARY } from '@/constants/constants';
import qs from 'qs';
import '@/styles/vocabularyDetail.scss';

let queryObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={queryObj.tab === 'title' ? api.vocabulary.title : api.vocabulary.singer}
      apiResponseInterceptor={(res: any) => {
        res.vocabularyType = queryObj.tab === 'title' ? 'TITLE' : 'SINGER';
        res.words = res.words.map((obj: any) => obj.searchWord);
        return res;
      }}
      styleTemplate="template-vocabulary"
      fieldFormat={[
        {
          keyId: 'id',
          title: 'No',
          field: DetailField.ReadOnly,
          schema: yup.string(),
          defaultValue: 'NEW',
        },
        {
          keyId: 'vocabularyType',
          title: '사전명',
          field: DetailField.ReadOnly,
          schema: yup.string(),
          defaultValue: queryObj.tab === 'title' ? 'TITLE' : 'SINGER',
        },
        {
          keyId: VOCABULARY.STANDARD,
          title: '대표단어',
          field: DetailField.InputBuilder({
            placeholder: '대표단어를 입력해주세요.',
            isTrimAll: true,
          }),
          schema: yup.string().required('대표단어를 입력해주세요.'),
        },
        {
          keyId: 'words',
          title: '유사단어',
          field: DetailField.SimilarWord,
          schema: yup.array(),
          defaultValue: [],
        },
        {
          keyId: 'regDate',
          title: 'DB 등록 일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
      ]}
    />
  );
}
