import Pagination from '@/components/Pagination';
import SelectBox from '@/components/SelectBox';
import SimpleForm from '@/components/SimpleForm';
import SimpleTable from '@/components/SimpleTable';
import { format } from 'date-fns';
import { get, isArray, isEmpty, isNumber } from 'lodash-es';
import qs from 'qs';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle, Search } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { alertMessage } from '@/utils/apiUtil';
import TvBrandContext from '@/TvBrandContext';

interface IInputFormat {
  title: string;
  keyId: string;
  field: React.ReactNode;
  defaultValue?: any;
}
interface ITableFormat {
  theads: string[] | React.ReactNode;
  columns: any[]; // string or React.ReactNode
  link?: {
    column: string;
    key: string;
  };
}
interface IProps {
  pageName?: string;
  api: any;
  onSearchValidation?: (params: any) => boolean;
  apiResponseInterceptor?: (res: any) => any;
  apiResponseListKey?: string;
  styleTemplate?: string;
  inputFormat: IInputFormat[];
  tableFormat: ITableFormat;
  customCreateButton?: React.ReactNode;
  bottomLeftMenu?: any;
  vocabularyTab?: any;
}

const changeDate = (obj: any, key: string) => {
  if (obj[key]) {
    try {
      obj[key] = format(new Date(obj[key]), 'yyyy-MM-dd HH:mm:ss'); // 공통
    } catch (e) {
      console.error('format e', e); // 에러나면 무시
    }
  }
};

let queryObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
console.log('qs queryObj', queryObj);

let skip = 0;
if (queryObj.skip) {
  skip = parseInt(queryObj.skip + '');
}
let takeDefault = 10;
if (queryObj.take) {
  takeDefault = parseInt(queryObj.take + '');
}
let activePageDefault = Math.floor(skip / takeDefault) + 1; // 1부터 시작
console.log('activePageDefault', activePageDefault);
export default function SearchModule(props: IProps) {
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(activePageDefault);
  const [take, setTake] = useState<number>(takeDefault);
  // const history = useHistory();
  const location = useLocation();

  const { brand } = useContext(TvBrandContext);

  const onChangePage = (num: number) => {
    window.location.href =
      '?' +
      qs.stringify({
        ...queryObj,
        skip: (num - 1) * take,
        take,
      });
  };
  const onChangetake = (value: string) => {
    window.location.href =
      '?' +
      qs.stringify({
        ...queryObj,
        skip: 0,
        take: value,
      });
  };
  const onSearch = () => {
    const params = {
      ...queryObj,
      ...values,
      skip: 0,
    };
    console.log('>>>>>onSearch', params);

    if (props.onSearchValidation && !props.onSearchValidation(params)) {
      return;
    }
    window.location.href = '?' + qs.stringify(params);
  };
  const getList = async (params: any) => {
    try {
      let res: any = await props.api.list(params, props.pageName, brand, queryObj);
      if (!res) {
        console.error('res is null');
        return;
      }
      if (props.apiResponseInterceptor) {
        res = props.apiResponseInterceptor(res);
      }
      let list = res;
      if (props.apiResponseListKey) {
        list = get(res, props.apiResponseListKey);
      } else {
        list = get(res, 'list');
      }
      if (!list) {
        throw new Error(`list is null.`);
      }
      if (!isArray(list)) {
        throw new Error('list is not array');
      }

      list.forEach((obj: any) => {
        changeDate(obj, 'regDate');
        changeDate(obj, 'loginDate');
      });
      setData(list || []);

      if (!isNumber(res.total)) {
        throw new Error('res.total is not number.\nres.total:' + res.total);
      }
      setTotalDataCount(res.total || 0);
    } catch (e) {
      alertMessage(e);
    } finally {
      setIsLoading(false);
    }
  };
  const gotoCreate = () => {
    window.location.href = `${location.pathname}/create`;
  };

  React.useEffect(() => {
    // page, take는 front에서 사용하는 용어 / skip, take는 server에서 사용하는 용어
    let defaultValueTemp = {
      take,
      skip,
    };
    props.inputFormat.forEach((obj: IInputFormat) => {
      defaultValueTemp = {
        ...defaultValueTemp,
        [obj.keyId]: queryObj[obj.keyId] ? queryObj[obj.keyId] : obj.defaultValue ? obj.defaultValue : '',
      };
    });
    setValues(defaultValueTemp);

    // defaultValueTemp = {
    //   ...defaultValueTemp,
    //   skip: (defaultValueTemp.page - 1) * defaultValueTemp.take,
    //   take: defaultValueTemp.take
    // }
    getList(defaultValueTemp);
  }, [brand]);
  return (
    <div style={{ marginTop: '20px' }}>
      {values && !isEmpty(values) && (
        // values가 세팅되면 화면에 구현(lazy...)
        <div className="search-box">
          <SimpleForm {...props} styleTemplate={props.styleTemplate} values={values} setValues={setValues} onSearch={onSearch} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button size="sm" variant="primary" onClick={onSearch} style={{ width: '100px' }}>
          <Search size="16" color="white" /> 검색
        </Button>
      </div>
      {props.vocabularyTab && props.vocabularyTab({ queryObj, take })}
      <div className="search-result">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{!isLoading ? totalDataCount.toLocaleString() + '건이 조회되었습니다.' : ''}</div>
          <SelectBox
            defaultValue={take + ''}
            list={[
              {
                label: '10',
                value: '10',
              },
              {
                label: '20',
                value: '20',
              },
              {
                label: '50',
                value: '50',
              },
            ]}
            onChange={onChangetake}
            style={{ width: '100px' }}
          />
        </div>
        <div className="result-table-box" style={{ marginTop: '10px' }}>
          <SimpleTable isLoading={isLoading} tableFormat={props.tableFormat} tableResult={data} />
        </div>
        <div className="pagination-box">
          <Pagination
            totalPages={Math.ceil(totalDataCount / take)}
            onChangePage={onChangePage}
            activePage={activePage}
            setActivePage={setActivePage}
            countPerPage={10}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div>
          {props.bottomLeftMenu &&
            props.bottomLeftMenu({
              totalDataCount,
              params: {
                ...queryObj,
                ...values,
              },
            })}
        </div>
        <div>
          {props.customCreateButton ? (
            props.customCreateButton
          ) : (
            <Button size="sm" variant="primary" style={{ width: '100px' }} onClick={gotoCreate} block>
              <PlusCircle size="16" /> 신규추가
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
