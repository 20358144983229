import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from '@/components/Layout';
import routes from '@/routes';
import Page404 from '@/pages/Page404';
import { TvBrandProvider } from './TvBrandContext';

// 신규추가 / 상세 페이지의 경우만 해당, 리스트조회 페이지는 해당사항 없음.
const getPageType = (props: any) => {
  console.log('props', props);
  if (props.match.params.mode === 'create') {
    return {
      mode: 'create',
      subTitle: '등록',
    };
  } else if (props.match.params.mode === 'info') {
    return {
      mode: 'info',
      subTitle: '상세',
      id: props.location.pathname.replace(props.match.url, '').replace('/', ''),
    };
  } else if (props.match.params.mode === 'detail') {
    return {
      mode: 'detail',
      subTitle: '수정',
      id: props.location.pathname.replace(props.match.url, '').replace('/', ''),
    };
    // props.location.pathname: /promotion/detail/d1
    // props.match.url: /promotion/detail
  }
  return {};
};

const routeList: any[] = [];
// flatten
routes.forEach((obj) => {
  if (obj.path) {
    routeList.push(obj);
  } else if (obj.children) {
    obj.children.forEach((obj2: any) => {
      if (obj2.path) {
        routeList.push(obj2);
      }
    });
  }
});

function App() {
  return (
    <TvBrandProvider>
      <div className="App">
        <Router>
          <Switch>
            {routeList.map((obj) => {
              if (obj.noLayout) {
                return <Route key={obj.title} path={obj.path} exact={obj.path === '/'} component={obj.component} />;
              }
              return (
                <Route
                  key={obj.title}
                  path={obj.path}
                  exact={obj.path === '/'}
                  render={(props) => {
                    return (
                      <>
                        <Layout routes={routes} />
                        <div className="content-wrap">
                          <div className="content">
                            <div>
                              <span style={{ fontSize: '1.5rem' }}>{obj.title}</span>
                              <span style={{ fontSize: '1rem' }}>
                                {getPageType(props).subTitle ? ' / ' + getPageType(props).subTitle : ''}
                              </span>
                            </div>
                            <div>
                              <obj.component {...props} pageType={getPageType(props)} />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              );
            })}
            <Route path="*" component={Page404} />
          </Switch>
        </Router>
      </div>
    </TvBrandProvider>
  );
}

export default App;
