import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Justify, PlusCircle } from 'react-bootstrap-icons';
import { isArray } from 'lodash-es';
import { toast } from 'react-toastify';
import DraggableList from '@/components/DraggableList';
import { format } from 'date-fns';
import { alertMessage } from '@/utils/apiUtil';
import TvBrandContext from '@/TvBrandContext';

interface IProps {
  pageName: string;
  api: any;
  theads: string[];
  columns: string[];
  link: {
    column: string;
    key: string;
  };
}
export default function DraggableModule(props: IProps) {
  const history = useHistory();
  const location = useLocation();
  const [firstData, setFirstData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);

  const { brand } = useContext(TvBrandContext);

  const getList = async () => {
    try {
      let res: any = await props.api.list(props.pageName, brand);
      if (!res) return;
      if (!isArray(res)) {
        throw new Error('res is not Array');
      }
      res.forEach((obj: any) => {
        obj.regDate = format(new Date(obj.regDate), 'yyyy-MM-dd HH:mm:ss');
      });

      res = res.sort((front, back) => front.order - back.order);

      setFirstData(res);
      setData(res);
    } catch (e) {
      alertMessage(e);
      setFirstData([]);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };
  const updateOrder = async () => {
    const orderSet = data.map((obj: any, index: number) => ({
      categoryId: obj.id,
      order: index + 1,
    }));
    try {
      const res: any = await props.api.updateOrder(orderSet, props.pageName, brand);
      toast('저장되었습니다.');
      getList();
    } catch (e) {
      alertMessage(e);
    }
  };
  const gotoCreate = () => {
    window.location.href = `${location.pathname}/create`;
  };
  React.useEffect(() => {
    getList();
  }, [brand]);
  return (
    <div className="detail-section" style={{ marginTop: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>{!isLoading ? (data.length || 0).toLocaleString() + '건이 조회되었습니다.' : ''}</div>
        <div>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={updateOrder}
            disabled={data.length === 0 || JSON.stringify(firstData) === JSON.stringify(data)}
          >
            <Justify size="16" /> 노출순서 저장
          </Button>
          <Button size="sm" variant="primary" onClick={gotoCreate} style={{ marginLeft: '10px' }}>
            <PlusCircle size="16" /> 카테고리 추가
          </Button>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <DraggableList
          isLoading={isLoading}
          data={data}
          setData={setData}
          theads={props.theads}
          columns={props.columns}
          link={props.link}
        />
      </div>
    </div>
  );
}
