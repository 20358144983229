import { alertMessage } from '@/utils/apiUtil';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface IProps {
  label: string;
  api: () => any;
  callbackMessage?: string;
  variant?: string;
  style?: any;
}

export default function Component({ label = '', variant = 'primary', api, callbackMessage, style }: IProps) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const onClick = async () => {
    setIsProcessing(true);
    try {
      await api();
      if (callbackMessage) {
        alert(callbackMessage);
      }
    } catch (e) {
      alertMessage(e);
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <Button size="sm" variant={variant} onClick={onClick} disabled={isProcessing} style={style}>
      {isProcessing ? (
        <>
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>{' '}
          {label}
        </>
      ) : (
        label
      )}
    </Button>
  );
}
