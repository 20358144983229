import TvBrandContext from '@/TvBrandContext';
import { alertMessage } from '@/utils/apiUtil';
import React from 'react';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import ProcessButton from './ProcessButton';
import { Reply } from 'react-bootstrap-icons';

interface IProps {
  data?: any;
  pageName: string;
  pageType?: {
    mode: string;
    subTitle?: string;
    id: string;
  };
  api: any;
  setParentData?: any;
}

export default function Component(props: IProps) {
  const [currentStatus, setCurrentStatus] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const toggleStatus = async (status: boolean) => {
    setCurrentStatus(status);
  };

  const getStatus = async () => {
    try {
      let res: boolean = await props.api.current(props.pageName);
      console.log('%c 👤: getStatus -> res ', 'font-size:16px;background-color:#759d3a;color:white;', res);
      setCurrentStatus(res);
    } catch (error) {
      alertMessage(error);
    }
  };

  // const showButtons = () => {
  //   if (!props.pageType) {
  //     return true;
  //   }

  //   if (props.pageType && props.pageType.mode !== 'detail') {
  //     return true;
  //   }

  //   return false;
  // };

  const setStatus = async () => {
    try {
      setIsProcessing(true);

      let res: any = await props.api.update({
        enableVideo: currentStatus,
      });
    } catch (error) {
      alertMessage(error);
    } finally {
      setIsProcessing(false);
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <div>
      <div className="search-box">
        <Button
          size="sm"
          variant={currentStatus === true ? 'primary' : 'ontline-secondary'}
          color="black"
          style={{ width: '100px' }}
          onClick={() => toggleStatus(true)}
        >
          사용함
        </Button>
        <Button
          size="sm"
          variant={!currentStatus ? 'primary' : 'ontline-secondary'}
          color="black"
          style={{ marginLeft: '10px', width: '100px' }}
          onClick={() => toggleStatus(false)}
        >
          사용 안함
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginLeft: '20px',
        }}
      >
        <ProcessButton label="수정" onClick={setStatus} isProcessing={isProcessing} style={{ width: '200px' }} />
      </div>
    </div>
  );
}
