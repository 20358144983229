import { CATEGORY } from '@/constants/constants';

const NOT_SELECT = 'NOT_SELECT';

const yn3List = [
  {
    label: '전체',
    value: '',
  },
  {
    label: 'Y',
    value: 'true',
  },
  {
    label: 'N',
    value: 'false',
  },
];
const dateTypeList = [
  {
    label: '등록일',
    value: 'REG_DATE',
  },
  {
    label: '최근접속일',
    value: 'LOGIN_DATE',
  },
];
const paymentDateTypeList = [
  {
    label: '결제일',
    value: 'PAY_DATE',
  },
];
const promotionStatus4List = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '적용전',
    value: 'READY',
  },
  {
    label: '적용중',
    value: 'ACTIVATED',
  },
  {
    label: '만료',
    value: 'FINISHED',
  },
];
const noticeRaio3List = [
  {
    label: '전체노출',
    value: 'COMMON',
  },
  {
    label: 'SAMSUNG TV 노출',
    value: CATEGORY.KARAOKE,
  },
  {
    label: 'LG TV 노출',
    value: CATEGORY.WEBOSKARAOKE,
  },
  {
    label: 'KYoungpay 노출',
    value: CATEGORY.BIXBY,
  },
  {
    label: 'KYoungpay LG 노출',
    value: CATEGORY.KYLG,
  },
];
const musicKeyGender = [
  {
    label: '남성',
    value: 'M',
  },
  {
    label: '여성',
    value: 'F',
  },
  {
    label: '선택안함',
    value: NOT_SELECT,
  },
];
const promotionCategoryList = [
  {
    label: '카테고리 선택',
    value: '',
  },
  {
    label: 'Samsung TV',
    value: CATEGORY.KARAOKE,
  },
  {
    label: 'Lg TV',
    value: CATEGORY.WEBOSKARAOKE,
  },
  {
    label: '금영 노래방',
    value: CATEGORY.BIXBY,
  },
];
const countryList = [
  {
    label: '선택해주세요.',
    value: '',
  },
  {
    label: 'KR',
    value: 'KR',
  },
  {
    label: 'EN',
    value: 'EN',
  },
];
const musicKeyList = [
  { label: '선택해주세요.', value: '' },
  { label: 'A', value: 'A' },
  { label: 'A#', value: 'A#' },
  { label: 'A#m', value: 'A#m' },
  { label: 'Ab', value: 'Ab' },
  { label: 'Abm', value: 'Abm' },
  { label: 'Am', value: 'Am' },
  { label: 'B', value: 'B' },
  { label: 'Bb', value: 'Bb' },
  { label: 'Bbm', value: 'Bbm' },
  { label: 'Bm', value: 'Bm' },
  { label: 'C', value: 'C' },
  { label: 'C#', value: 'C#' },
  { label: 'C#m', value: 'C#m' },
  { label: 'Cm', value: 'Cm' },
  { label: 'D', value: 'D' },
  { label: 'D#', value: 'D#' },
  { label: 'D#m', value: 'D#m' },
  { label: 'Db', value: 'Db' },
  { label: 'Dbm', value: 'Dbm' },
  { label: 'Dm', value: 'Dm' },
  { label: 'E', value: 'E' },
  { label: 'E#m', value: 'E#m' },
  { label: 'Eb', value: 'Eb' },
  { label: 'Ebm', value: 'Ebm' },
  { label: 'Em', value: 'Em' },
  { label: 'F', value: 'F' },
  { label: 'F#', value: 'F#' },
  { label: 'F#m', value: 'F#m' },
  { label: 'Fm', value: 'Fm' },
  { label: 'G', value: 'G' },
  { label: 'G#', value: 'G#' },
  { label: 'G#m', value: 'G#m' },
  { label: 'Gb', value: 'Gb' },
  { label: 'Gbm', value: 'Gbm' },
  { label: 'Gm', value: 'Gm' },
  { label: 'M', value: 'M' },
];
export default {
  dateTypeList,
  paymentDateTypeList,
  yn3List,
  promotionStatus4List,
  noticeRaio3List,
  musicKeyGender,
  promotionCategoryList,
  countryList,
  musicKeyList,
  NOT_SELECT,
};
