import React from 'react';
import { Alert } from 'react-bootstrap';
import { ExclamationCircle } from 'react-bootstrap-icons';

export default function Component({ children }: any) {
  return (
    <div style={{ marginTop: '10px' }}>
      <Alert variant="danger">
        <div>
          <ExclamationCircle size="20" color="black" />
          <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>주의</span>
          <div style={{ marginTop: '10px' }}>{children}</div>
        </div>
      </Alert>
    </div>
  );
}
