import React from 'react';
import * as yup from 'yup';
import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import options from '@/constants/options';

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={api.songSimilar}
      fieldFormat={[
        {
          keyId: 'songId',
          title: '곡번호',
          field: DetailField.InputBuilder({ placeholder: '곡번호를 입력해주세요.' }),
          schema: yup
            .string()
            .matches(/^[0-9]+$/, '곡번호는 숫자로만 입력해주세요.')
            .required('곡번호를 입력해주세요.'),
        },
        {
          keyId: 'searchKeyword',
          title: '유사검색어',
          field: DetailField.InputBuilder({ placeholder: '유사검색어를 입력해주세요.' }),
          schema: yup.string().required('유사검색어를 입력해주세요.'),
        },
        {
          keyId: 'regDate',
          title: 'DB 등록 일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
      ]}
    />
  );
}
