import React, { useState } from 'react';
import { subMonths, subDays, format } from 'date-fns';

import SearchModule from '@/modules/SearchModule';
import SearchField from '@/components/SearchField';
import { getTextDate } from '@/utils/date';
import api from '@/api';
import TVBrandButtons from '@/components/TVBrandButtons';

export default function Component(props: any) {
  return (
    <div>
      <TVBrandButtons></TVBrandButtons>

      <SearchModule
        {...props}
        pageName="PRIVACY_AGREEMENT"
        api={api.terms}
        inputFormat={[
          {
            title: '키워드',
            keyId: 'keyword',
            field: SearchField.Input({
              placeholder: '제목으로 검색하실 수 있습니다.',
            }),
          },
        ]}
        tableFormat={{
          theads: ['제목', '시행공고일', '등록일'],
          columns: ['title', (item: any) => getTextDate(item.enforcementDate), 'regDate'],
          link: { column: 'title', key: 'id' },
        }}
      />
    </div>
  );
}
