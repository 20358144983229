import React, { useState } from 'react';
import { Container, Form, Row, Col, InputGroup, Button, Spinner } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import { Image } from 'react-bootstrap-icons';
import options from '@/constants/options';
import DatePicker from 'react-datepicker';
import { format, parse, differenceInHours } from 'date-fns';
import { ko } from 'date-fns/locale';
import { CalendarRange, X } from 'react-bootstrap-icons';
import Wysiwyg from '@/components/Wysiwyg';
import api from '@/api';
import { find, isArray } from 'lodash-es';
import { getFormatPrice } from '@/utils/price';
import { alertMessage } from '@/utils/apiUtil';

// 페이지별
import { CATEGORY, PROMOTION, VOCABULARY } from '@/constants/constants';
/**
 * 공통
 */
interface IRow {
  title: string;
  keyId: string;
  style?: any;
  className?: string;
  children: any;
}
const RowContainer = (props: IRow) => {
  return (
    <div className={`item ${props.className ? props.className : ''}`} style={props.style}>
      <div className="item-title" dangerouslySetInnerHTML={{ __html: props.title }} />
      <div className={`item-field name-${props.keyId}`}>{props.children}</div>
    </div>
  );
};

/**
 * input
 */
interface IInputBuilder {
  placeholder?: string;
  disclaimer?: string;
  isTrimAll?: boolean;
}
interface IInputProps {
  keyId: string;
  title: string;
  formik: any;
  placeholder?: string;
  disclaimer?: string;
}

const InputBuilder = ({ placeholder, disclaimer, isTrimAll }: IInputBuilder) => {
  return (props: IInputProps) => {
    const { keyId } = props;
    const { values, errors, touched, setFieldValue } = props.formik;
    return (
      <RowContainer {...props}>
        <Form.Control
          type="text"
          size="sm"
          placeholder={placeholder}
          name={keyId}
          focus-name={keyId}
          value={values[keyId]}
          onChange={(e) => {
            if (isTrimAll) {
              setFieldValue(keyId, (e.target.value || '').replace(/ /, ''));
            } else {
              setFieldValue(keyId, e.target.value);
            }
          }}
          isInvalid={!!touched[keyId] && !!errors[keyId]}
        />
        <div className="text-disclaimer">{disclaimer && disclaimer}</div>
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};
const InputCategory = InputBuilder({
  placeholder: '카테고리명을 입력해주세요.',
  disclaimer: '* 카테고리명은 한글 N글자, 영문 N글자 까지 잘림 없이 표기됩니다.',
});

/**
 * colorPicker
 */
interface IColorPicker {
  title: string;
  keyId: string;
  formik: any;
}
const ColorPicker = function (props: IColorPicker) {
  const { keyId } = props;
  const { value, values, errors, touched, setFieldValue } = props.formik;
  return (
    <RowContainer {...props}>
      <div>HEX: {values[keyId]}</div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <ChromePicker
          disableAlpha={true}
          color={values[keyId] || ''}
          onChangeComplete={(color) => {
            if (!color) return;
            setFieldValue(keyId, color.hex);
          }}
        />
        <div className="artist-box" style={{ backgroundColor: values[keyId] || '' }}>
          <div className="artist">Artist</div>
          <div className="category">{values['title']}</div>
          <div className="album-box">
            <div className="album">
              <Image size="80" color="lightgray" />
            </div>
            <div className="album">
              <Image size="80" color="lightgray" />
            </div>
            <div className="album">
              <Image size="80" color="lightgray" />
            </div>
          </div>
        </div>
      </div>
      {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
    </RowContainer>
  );
};

/**
 * imageUploader
 */
interface IImageUploaderBuilder {
  api: (_: any) => any;
}
interface IImageUploaderComponent {
  title: string;
  keyId: string;
  formik: any;
}
const ImageUploaderBuilder = ({ api }: IImageUploaderBuilder) => {
  return function ImageUploaderComponent(props: IImageUploaderComponent) {
    const { keyId } = props;
    const { values, errors, touched, setFieldValue } = props.formik;

    const ref = React.useRef<HTMLInputElement>(null);
    const [data, setData] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const uploadImage = async (e: any) => {
      console.log('e.target.value', e.target.value);
      setData(e.target.value);
      setIsProcessing(true);
      try {
        if (!ref || !ref.current) {
          throw new Error('ref is null');
        }
        const files = ref.current.files;
        if (!files || files.length < 1) {
          throw new Error('files is null');
        }
        const formData = new FormData();
        formData.append('thumbnail', files[0]);
        const response: any = await api(formData);
        console.log('response', response);
        if (!response) {
          throw new Error('response is null');
        }
        // const url = 'https://images.unsplash.com/photo-1565073182887-6bcefbe225b1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80';
        setFieldValue(keyId, response.key);
      } catch (e) {
        alertMessage(e);
      } finally {
        setIsProcessing(false);
      }
    };
    const deleteImage = () => {
      setData('');
      setFieldValue(keyId, '');
    };
    const getImageUrl = (imageKey: string) => {
      return imageKey ? `${process.env.REACT_APP_RESOURCE_HOST}/${imageKey}` : '';
    };
    return (
      <RowContainer {...props}>
        <Form.File.Input
          ref={ref}
          name={keyId}
          focus-name={keyId}
          value={data}
          accept=".gif, .jpg, .png"
          // accept="image/gif, image/jpeg, image/png"
          // accept="image/*"
          isInvalid={!!touched[keyId] && !!errors[keyId]}
          onChange={uploadImage}
        />
        {isProcessing && (
          <div style={{ padding: '4px' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!isProcessing && values[keyId] && (
          <div
            style={{
              position: 'relative',
              width: '200px',
              border: '1px solid gray',
              minHeight: '50px',
              margin: '4px',
            }}
          >
            <img src={getImageUrl(values[keyId])} style={{ width: '200px' }} alt="" />
            <div style={{ position: 'absolute', top: 4, right: 4 }}>
              <Button size="sm" variant="info" onClick={deleteImage}>
                <X size="16" color="white" />
              </Button>
            </div>
          </div>
        )}
        <div className="text-disclaimer">*0000*0000px (jpg, XXX 확장자 업로드 가능합니다.)</div>
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};
const ContentsImageUploader = ImageUploaderBuilder({
  api: api.uploadContentsImage,
});
const MainBannerImageUploader = ImageUploaderBuilder({
  api: api.uploadMainBannerImage,
});

/**
 * imageUpload and textarea
 *
 * 필요한 field(koImage, enImage, koContent, enContent)
 */
interface IImageUploaderTextareaBuilder {
  api: (_: any) => any;
}
interface IImageUploaderTextareaComponent {
  title: string;
  keyId: string;
  formik: any;
  placeholder: string;
  disclaimer: string;
}
const ImageUploaderTextareaBuilder = ({ api }: IImageUploaderTextareaBuilder) => {
  return function ImageUploaderTextareaComponent(props: IImageUploaderTextareaComponent) {
    const { keyId, placeholder, disclaimer } = props;
    const { values, errors, touched, setFieldValue } = props.formik;

    const ref = React.useRef<HTMLInputElement>(null);
    const [data, setData] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const uploadImage = async (e: any) => {
      console.log('e.target.value', e.target.value);
      setData(e.target.value);
      setIsProcessing(true);
      try {
        if (!ref || !ref.current) {
          throw new Error('ref is null');
        }
        const files = ref.current.files;
        if (!files || files.length < 1) {
          throw new Error('files is null');
        }
        const formData = new FormData();
        formData.append('image', files[0]);
        const response: any = await api(formData);
        console.log('response', response);
        if (!response) {
          throw new Error('response is null');
        }
        // const url = 'https://images.unsplash.com/photo-1565073182887-6bcefbe225b1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80';
        setFieldValue(getImageKey(), response.key);
        setFieldValue(keyId, response.key || values[getContentKey()] ? 'true' : '');
      } catch (e) {
        alertMessage(e);
      } finally {
        setIsProcessing(false);
      }
    };
    const getImageUrl = (imageKey: string) => {
      return imageKey ? `${process.env.REACT_APP_RESOURCE_HOST}/${imageKey}` : '';
    };
    const deleteImage = () => {
      setData('');
      setFieldValue(getImageKey(), '');
      setFieldValue(keyId, '' || values[getContentKey()] ? 'true' : '');
    };
    const getImageKey = () => {
      return keyId === 'koCustom' ? 'koImage' : 'enImage';
    };
    const getContentKey = () => {
      return keyId === 'koCustom' ? 'koContent' : 'enContent';
    };
    return (
      <RowContainer {...props}>
        <div className="h2-title">[첨부 이미지]</div>
        <Form.File.Input
          ref={ref}
          value={data}
          accept=".gif, .jpg, .png"
          onChange={uploadImage}
          isInvalid={!!touched[keyId] && !!errors[keyId]}
        />
        {isProcessing && (
          <div style={{ padding: '4px' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!isProcessing && values[getImageKey()] && (
          <div
            style={{
              position: 'relative',
              width: '200px',
              border: '1px solid gray',
              minHeight: '50px',
              margin: '4px',
            }}
          >
            <img src={getImageUrl(values[getImageKey()])} style={{ width: '200px' }} alt="" />
            <div style={{ position: 'absolute', top: 4, right: 4 }}>
              <Button size="sm" variant="info" onClick={deleteImage}>
                <X size="16" color="white" />
              </Button>
            </div>
          </div>
        )}
        <div className="text-disclaimer">*0000*0000px (jpg, XXX 확장자 업로드 가능합니다.)</div>

        <div className="h2-title" style={{ marginTop: '20px' }}>
          [내용]
        </div>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder={placeholder}
          name={keyId}
          value={values[getContentKey()]}
          onChange={(e) => {
            setFieldValue(getContentKey(), e.target.value);
            setFieldValue(keyId, values[getImageKey()] || e.target.value ? 'true' : '');
          }}
          isInvalid={!!touched[keyId] && !!errors[keyId]}
        />
        <div className="text-disclaimer">{disclaimer && disclaimer}</div>
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};
const ImageUploaderTextarea = ImageUploaderTextareaBuilder({
  api: api.uploadNoticeImage,
});

/**
 * select
 */
interface ISelectBox {
  label: string;
  value: string;
}
interface ISelectBoxBuilder {
  list: ISelectBox[];
}
interface ISelectBoxBuilderComponent {
  title: string;
  keyId: string;
  formik: any;
}
const SelectBoxBuilder = ({ list }: ISelectBoxBuilder) => {
  return function SelectBoxBuilderComponent(props: ISelectBoxBuilderComponent) {
    const { keyId } = props;
    const { values, errors, touched, setFieldValue } = props.formik;
    return (
      <RowContainer {...props}>
        <Form.Control
          as="select"
          size="sm"
          name={keyId}
          focus-name={keyId}
          value={values[keyId]}
          onChange={(e) => {
            setFieldValue(keyId, e.target.value);
          }}
          isInvalid={!!touched[keyId] && !!errors[keyId]}
        >
          {list.map((obj: ISelectBox, index: number) => {
            return (
              <option key={index} value={obj.value}>
                {obj.label}
              </option>
            );
          })}
        </Form.Control>
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};
interface ISelectBoxWithApiBuilder {
  api: () => any;
}
interface ISelectBoxWithApiBuilderComponent {
  title: string;
  keyId: string;
  formik: any;
}
const SelectBoxWithApiBuilder = ({ api }: ISelectBoxWithApiBuilder) => {
  const [listValue, setListValue] = useState<any[] | null>(null);
  const [error, setError] = useState<boolean>(false);

  React.useEffect(() => {
    api()
      .then((res: any) => {
        if (res && res.length > 0) {
          setListValue([
            {
              label: '전체',
              value: '',
            },
            ...res,
          ]);
        } else {
          setError(true);
        }
      })
      .catch((e: any) => {
        setError(true);
      });
  }, []);
  return function SelectBoxWithApiBuilderComponent(props: ISelectBoxWithApiBuilderComponent) {
    const { keyId } = props;
    const { values, errors, touched, setFieldValue } = props.formik;
    return (
      <RowContainer {...props}>
        {error && <div>loading error...</div>}
        {!error && !listValue && (
          <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        {listValue && (
          <Form.Control
            as="select"
            size="sm"
            name={keyId}
            focus-name={keyId}
            value={values[keyId]}
            onChange={(e) => {
              setFieldValue(keyId, e.target.value);
            }}
            isInvalid={!!touched[keyId] && !!errors[keyId]}
          >
            {listValue.map((obj: ISelectBox, index: number) => {
              return (
                <option key={index} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
          </Form.Control>
        )}
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};

/**
 * radio
 */
interface IRadio {
  label: string;
  value: string;
}
interface IRadioBuilder {
  list: IRadio[];
}
interface IRadioProps {
  title: string;
  keyId: string;
  formik: any;
}
const RadioBuilder = ({ list }: IRadioBuilder) => {
  return function RadioBuilderComponent(props: IRadioProps) {
    const { keyId } = props;
    const { values, errors, touched, setFieldValue } = props.formik;
    return (
      <RowContainer {...props} style={{ height: '48px' }}>
        <div style={{ display: 'flex' }} focus-name={keyId}>
          {list.map((obj: IRadio, index: number) => {
            return (
              <Form.Check
                key={index}
                inline
                label={obj.label}
                type="radio"
                name={keyId}
                id={keyId + '-' + index}
                value={obj.value}
                checked={obj.value === values[keyId]}
                onChange={(e) => setFieldValue(keyId, e.target.value)}
                // isInvalid={!!props.formik.touched[props.keyId] && !!props.formik.errors[props.keyId]} // css 스타일이 틀어진다.
              />
            );
          })}
        </div>
        <div>{errors[keyId] && <div className="text-error">{errors[keyId]}</div>}</div>
      </RowContainer>
    );
  };
};

/**
 * checkbox
 */
interface ICheckBoxBuilder {
  label: string;
}
interface ICheckBoxProps {
  title: string;
  keyId: string;
  formik: any;
}
const CheckBoxBuilder = ({ label }: ICheckBoxBuilder) => {
  return function CheckBoxBuilderComponent(props: ICheckBoxProps) {
    const { keyId } = props;
    const { values, errors, setFieldValue } = props.formik;
    return (
      <RowContainer {...props}>
        <Form.Check type="checkbox" label={label} checked={!!+values[keyId]} onChange={(e) => setFieldValue(keyId, e.target.checked)} />
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </RowContainer>
    );
  };
};

/**
 * datePicker
 */
interface IDatePicker {
  keyId: string;
  title: string;
  formik: any;
}
const DateSingle = function (props: IDatePicker) {
  const { keyId } = props;
  const { values, errors, touched, setFieldValue } = props.formik;

  // props.formik.values[props.keyId] 값이 처음에는 '' 일 수 있다. api 후에 값이 채워짐.
  const ExampleCustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <div style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
      <Form.Control size="sm" type="text" ref={ref} onClick={onClick} defaultValue={value} focus-name={keyId} readOnly />
      <div style={{ padding: '0 6px', cursor: 'pointer' }}>
        <CalendarRange size="18" color="purple" onClick={onClick} />
      </div>
    </div>
  ));
  const getDate = () => {
    if (values[keyId]) {
      const d: Date = new Date(values[keyId]);
      if (!isNaN(d.getTime())) {
        return d;
      } else {
        console.error('values[keyId] is invalid', values[keyId]);
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <RowContainer {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          locale="ko"
          selected={getDate()}
          dateFormat="yyyy-MM-dd"
          customInput={<ExampleCustomInput />}
          onChange={(date: any) => {
            setFieldValue(keyId, format(date, 'yyyy-MM-dd'));
          }}
        />
        {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
      </div>
    </RowContainer>
  );
};

/**
 * 프로모션에서 한번만 사용됨
 * 필요한 field(startDate, endDate)
 *
 * showTimeSelect: hour 표시
 * timeIntervals: 60분 간격
 * h:mm aa --> 6:00 오전
 */
interface IDateRangeWithHour {
  title: string;
  keyId: string;
  formik: any;
}
const DateRangeWithHour = function (props: IDateRangeWithHour) {
  const { keyId } = props;
  const { values, errors, touched, setFieldValue } = props.formik;

  const ExampleCustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
        <Form.Control size="sm" type="text" ref={ref} onClick={onClick} defaultValue={value} focus-name={keyId} readOnly />
        <div style={{ padding: '0 6px', cursor: 'pointer' }}>
          <CalendarRange size="18" color="purple" onClick={onClick} />
        </div>
      </div>
    );
  });
  const getFormat = (date: Date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };
  const getStartDate = () => {
    if (values[PROMOTION.START_DATE]) {
      // ie11 에서는 2021-04-07 00:00:00 string을 new Date 생성시 사용할 수 없다.
      // const d: Date = new Date(values[PROMOTION.START_DATE]);

      /* let tempDate = values[PROMOTION.START_DATE].replace("Z", "") */
      const d: Date = new Date(values[PROMOTION.START_DATE]);

      if (!isNaN(d.getTime())) {
        return d;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const getEndDate = () => {
    if (values[PROMOTION.END_DATE]) {
      /* let tempDate = values[PROMOTION.END_DATE].replace("Z", "") */
      const d: Date = new Date(values[PROMOTION.END_DATE]);

      if (!isNaN(d.getTime())) {
        return d;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <RowContainer {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          locale="ko"
          showTimeSelect
          timeIntervals={60}
          selected={getStartDate()}
          dateFormat="yyyy-MM-dd h:mm aa"
          customInput={<ExampleCustomInput />}
          filterDate={(date) => {
            const d = getEndDate();
            if (d) {
              // WHY: 일단 날짜는 선택할 수 있어야 하므로.
              // return new Date(getFormat(date)) <= new Date(format(d, 'yyyy-MM-dd 23:59:59'));
              return date <= new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
            }
            return true;
          }}
          filterTime={(date: Date) => {
            const d = getEndDate();
            if (d) {
              return differenceInHours(d, date) >= 0; // d가 date보다 크면 양수
            }
            return true;
          }}
          onChange={(date: any) => {
            setFieldValue(PROMOTION.START_DATE, getFormat(date));
            setFieldValue(keyId, getFormat(date) && values[PROMOTION.END_DATE] ? 'true' : '');
          }}
        />
        <div style={{ width: '20px' }}> ~ </div>
        <DatePicker
          locale="ko"
          showTimeSelect
          timeIntervals={60}
          selected={getEndDate()}
          dateFormat="yyyy-MM-dd aa h:mm"
          customInput={<ExampleCustomInput />}
          filterDate={(date) => {
            const d = getStartDate();
            if (d) {
              // return new Date(format(d, 'yyyy-MM-dd 00:00:00')) <= new Date(getFormat(date));
              return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0) <= date;
            }
            return true;
          }}
          filterTime={(date: Date) => {
            const d = getStartDate();
            if (d) {
              return differenceInHours(date, d) >= 0;
            }
            return true;
          }}
          onChange={(date: any) => {
            setFieldValue(PROMOTION.END_DATE, getFormat(date));
            setFieldValue(keyId, values[PROMOTION.START_DATE] && getFormat(date) ? 'true' : '');
          }}
        />
      </div>
      <div style={{ marginTop: '6px' }}>
        * 유효기간은 KST(UTC+9) 한국 표준시 기준으로 적용되며 프로모션시 해외 시간은 별도 계산 부탁드립니다.
      </div>
      {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
    </RowContainer>
  );
};

/**
 * editor
 */
interface IEditor {
  formik: any;
  keyId: string;
  title: string;
}
const Editor = function (props: IEditor) {
  const { keyId } = props;
  const { values, errors, touched, setFieldValue } = props.formik;
  return (
    <RowContainer className={`item-field-editor keyId-${keyId}`} {...props}>
      <Wysiwyg
        defaultValue={values[keyId]}
        onChange={(value: string) => {
          const rawText = value.replace(/(<([^>]+)>)/gi, '').trim();
          setFieldValue(keyId, rawText === '' ? '' : value); // 내용이 없이 태그만 있는 경우 태그 제거
        }}
      />
      {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
    </RowContainer>
  );
};

// [공통] ReadOnly
interface IReadOnly {
  formik: any;
  keyId: string;
  title: string;
}
const ReadOnly = function (props: IReadOnly) {
  return (
    <RowContainer {...props} style={{ height: '48px' }}>
      {props.formik.values[props.keyId]}
    </RowContainer>
  );
};

/**
 * 기타
 */

/**
 * promotion detail
 */
interface IProduct {
  id: number;
  koName: string;
  enName: string;
  region: string; // KR, GLOBAL
  amount: number;
}
interface IActivePriceSelect {
  title: string;
  keyId: string;
  formik: any;
}
// 프로모코드관리: 적용상품 필드
const ActivePriceSelect = function (props: IActivePriceSelect) {
  const { keyId } = props;
  const { values, errors, touched, setFieldValue } = props.formik;

  const [list, setList] = useState<ISelectBox[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const getList = async (category: string) => {
    if (category === CATEGORY.KARAOKE || category === CATEGORY.BIXBY || category === CATEGORY.WEBOSKARAOKE) {
      const res: any = await api.getProduct({
        category,
        subscription: false,
      }); // api 고정
      if (res) {
        setProductList(res);
        const options = res.map((obj: IProduct) => ({
          label: `[${obj.region}] ${obj.koName}`,
          value: obj.id,
        }));
        setList([
          {
            label: '상품선택',
            value: '',
          },
          ...options,
        ]);

        // 상세화면에서 기본값 매칭
        const searchedProduct = find(res, {
          id: values[PROMOTION.PRODUCT_ID],
        });
        setFieldValue(PROMOTION.REGION, searchedProduct ? searchedProduct.region : '');
        setFieldValue(keyId, searchedProduct ? searchedProduct.amount : '');
      }
    }
  };
  React.useEffect(() => {
    if (values[PROMOTION.CATEGORY] !== '') {
      getList(values[PROMOTION.CATEGORY]);
    }
  }, []);
  return (
    <RowContainer {...props}>
      <div style={{ display: 'flex' }}>
        <Form.Control
          as="select"
          size="sm"
          value={values[PROMOTION.CATEGORY]}
          focus-name={keyId}
          style={{ width: '200px' }}
          onChange={(e) => {
            setFieldValue(PROMOTION.CATEGORY, e.target.value);
            setList([]);
            if (e.target.value === '') {
              setFieldValue(PROMOTION.REGION, '');
              setFieldValue(keyId, '');
              setFieldValue(PROMOTION.PRODUCT_ID, '');
            } else {
              getList(e.target.value);
            }
          }}
        >
          {options.promotionCategoryList.map((obj: ISelectBox, index: number) => {
            return (
              <option key={index} value={obj.value}>
                {obj.label}
              </option>
            );
          })}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          value={values[PROMOTION.PRODUCT_ID]}
          style={{ marginLeft: '10px' }}
          disabled={values[PROMOTION.CATEGORY] === ''}
          onChange={(e) => {
            setFieldValue(PROMOTION.PRODUCT_ID, e.target.value);
            const searchedProduct = find(productList, {
              id: parseInt(e.target.value),
            });
            setFieldValue(PROMOTION.REGION, searchedProduct ? searchedProduct.region : '');
            setFieldValue(keyId, searchedProduct ? searchedProduct.amount : ''); // amount는 getProduct api response의 field
          }}
        >
          {list &&
            list.map((obj: ISelectBox, index: number) => {
              return (
                <option key={index} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
        </Form.Control>
      </div>
      {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
    </RowContainer>
  );
};
// 프로모코드관리: 결제금액 필드
interface IPaymentPrice {
  title: string;
  keyId: string;
  formik: any;
}
const PaymentPrice = function (props: IPaymentPrice) {
  const region = props.formik.values[PROMOTION.REGION];
  const productPrice = getFormatPrice(region, parseFloat(props.formik.values[PROMOTION.PRODUCT_PRICE] || 0));
  const saleAmount = getFormatPrice(region, parseFloat(props.formik.values[PROMOTION.SALE_AMOUNT] || 0));
  const paymentAmount = getFormatPrice(
    region,
    parseFloat(props.formik.values[PROMOTION.PRODUCT_PRICE] || 0) - parseFloat(props.formik.values[PROMOTION.SALE_AMOUNT] || 0),
  );
  return (
    <RowContainer {...props} style={{ height: '48px' }}>
      <div>
        <table className="promotion-payment-table">
          <thead>
            <tr>
              <th>상품가</th>
              <th style={{ width: '20px' }}></th>
              <th>할인금액</th>
              <th style={{ width: '20px' }}></th>
              <th>결제금액</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{productPrice}</td>
              <td>-</td>
              <td>{saleAmount}</td>
              <td>=</td>
              <td>{paymentAmount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </RowContainer>
  );
};

/**
 * 어휘사전 페이지
 */
const SimilarWord = function (props: IInputProps) {
  const { keyId } = props;
  const { values, errors, touched, setFieldValue } = props.formik;

  const [keyword, setKeyword] = useState<string>('');

  const addItem = () => {
    if (keyword === '') return;
    const newArray = [...values[keyId], keyword];
    setFieldValue(keyId, newArray);
    setKeyword('');
  };
  const delteFn = (index: number) => {
    const newArray = JSON.parse(JSON.stringify(values[keyId]));
    newArray.splice(index, 1);
    setFieldValue(keyId, newArray);
  };
  return (
    <RowContainer {...props}>
      <div style={{ display: 'flex' }}>
        <Form.Control
          type="text"
          size="sm"
          value={keyword}
          style={{ width: '200px' }}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <Button size="sm" variant="primary" style={{ marginLeft: '4px' }} onClick={addItem} focus-name={keyId} disabled={keyword === ''}>
          추가
        </Button>
      </div>
      <div className="word-box">
        {values[VOCABULARY.STANDARD] && (
          <div className="word">
            <div className="text">{values[VOCABULARY.STANDARD]}</div>
            <div className="delete readonly">X</div>
          </div>
        )}
        {values[keyId] &&
          isArray(values[keyId]) &&
          values[keyId].map((text: string, index: number) => {
            return (
              <div key={index} className="word">
                <div className="text">{text}</div>
                <div
                  className="delete"
                  onClick={() => {
                    delteFn(index);
                  }}
                >
                  X
                </div>
              </div>
            );
          })}
      </div>
      {errors[keyId] && <div className="text-error">{errors[keyId]}</div>}
    </RowContainer>
  );
};

export default {
  InputBuilder,
  InputCategory,
  ColorPicker,
  ContentsImageUploader,
  MainBannerImageUploader,
  ImageUploaderTextarea,
  SelectBoxBuilder,
  SelectBoxWithApiBuilder,
  RadioBuilder,
  CheckBoxBuilder,
  DateSingle,
  Editor,
  DateRangeWithHour,
  ReadOnly,
  ActivePriceSelect,
  PaymentPrice,
  SimilarWord,
};
