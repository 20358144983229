import React from 'react';
import * as yup from 'yup';

import api from '@/api';
import DetailField from '@/components/DetailField';
import SimpleDetailModule from '@/modules/SimpleDetailModule';
import options from '@/constants/options';

export default function Component(props: any) {
  return (
    <SimpleDetailModule
      pageType={props.pageType}
      api={api.notice}
      apiResponseInterceptor={(res: any) => {
        res.koCustom = res.koImage + ',' + res.koContent;
        res.enCustom = res.enImage + ',' + res.enContent;
        return res;
      }}
      fieldFormat={[
        {
          keyId: 'category',
          title: '게시구분',
          field: DetailField.RadioBuilder({ list: options.noticeRaio3List }),
          schema: yup
            .string()
            .required('게시구분을 선택해주세요.')
            .oneOf(
              options.noticeRaio3List.map((obj) => obj.value),
              '가수구분을 선택해주세요.',
            ),
        },
        {
          keyId: 'koTitle',
          title: '국문 제목',
          field: DetailField.InputBuilder({ placeholder: '국문제목을 입력해주세요.' }),
          schema: yup.string().required('국문제목을 입력해주세요.'),
        },
        {
          keyId: 'koCustom',
          title: '국문 본문',
          field: DetailField.ImageUploaderTextarea,
          schema: yup.string().required('국문 이미지 또는 내용 2개중 1개는 반드시 입력되어있어야 합니다'),
        },
        {
          keyId: 'koImage',
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: 'koContent',
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: 'enTitle',
          title: '영문 제목',
          field: DetailField.InputBuilder({ placeholder: '영문제목을 입력해주세요.' }),
          schema: yup.string().required('영문제목을 입력해주세요.'),
        },
        {
          keyId: 'enCustom',
          title: '영문 본문',
          field: DetailField.ImageUploaderTextarea,
          schema: yup.string().required('영문 이미지 또는 내용 2개중 1개는 반드시 입력되어있어야 합니다'),
        },
        {
          keyId: 'enImage',
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: 'enContent',
          title: '',
          field: null,
          schema: yup.string(),
        },
        {
          keyId: 'regDate',
          title: '등록일시',
          field: props.pageType.mode === 'detail' ? DetailField.ReadOnly : null,
        },
      ]}
    />
  );
}
